//global variables that may be overwritten at client layer

//Ribbon
$suide-bar-fullscreenmode-hidden: 			false !default;			//hide the fullscreen mode button in the ribbon


//Edit Mode
$edit-mode-panel-background-color:			#F2F2F2 !default;		//default background color of edit mode panel in page layout
$edit-mode-panel-field-padding-top:			0px !default;
$edit-mode-panel-field-padding-right:		10px !default;
$edit-mode-panel-field-padding-bottom:		5px !default;
$edit-mode-panel-field-padding-left:		10px !default;


//scroll list plugin
$plugin-scroll-list-wrapper-padding-top:	0px !default;
$plugin-scroll-list-wrapper-padding-side:	15px !default;
$plugin-scroll-list-button-padding-top:		0px !default;
$plugin-scroll-list-button-padding-side:	5px !default;

