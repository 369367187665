// second level menu item container that wraps every except top level menu item
//
// Instructions: extend UL.dynamic
// -----------------------------------------------------------------------------

%ul-dynamic 
{ 
	@extend %box-shadow-remove; // sharepoint reset - remove box shadow
	border:0px; // sharepoint reset
	padding:0px; // sharepoint reset
	list-style:none;
	white-space:nowrap; // prevent menu item text from wrapping to another line
	width:auto !important;  // prevent sharepoint from forcing width
	min-width:$navigation-item-width;
	z-index:1002; // prevent menu from going behind searchbox webparts

	LI.dynamic 
	{
		@extend %top-nav-menu-item-li;
		
		> A {
			@extend %top-nav-menu-item-a;
		}
	}
}