// Pill Navigation button
// -----------------------------------------------------------------------------

BUTTON.searchbar-toggle, // TODO: rename class in HTML
{
	@extend %btn-toggle;
	
	background: url('../images/btn-toggle-searchbar.png') no-repeat;
	
	right:15px; // float to right, minus 15px bootstrap spacing
}