.pm-layout-home {
    #s4-bodyContainer {
        .main-content {
            overflow-x: hidden; //this is to remove the scrollbar with full width

            .container {
                //When specified as fluid
                width: 100% !important; //need this to extend out the bands inside the containers
                padding: 0; //changing out padding to see if this removes the scrollbar
                
            }
        }
    }
}