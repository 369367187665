// Searchbox in header
//
// main difference is that it in desktop view, it floats right, and has fixed width

.ms-mpSearchBox
{ // header searchbox wrapper class
	#SearchBox // Searchbox webpart
	{	
		.ms-srch-sb 
		{
			width:100%; // prevents input overflow?

			>INPUT {
				@extend %reset-sharepoint-input;
				width:$searchbox-input-width;
			}
		}
	}
}