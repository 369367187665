// A mobile solution for header searchbox
//
// Note: This styling is in a separate file because its an  optional solution
// -----------------------------------------------------------------------------

.ms-mpSearchBox // header searchbox wrapper
{
    &.mobile 
    {
        display:block; // show if the searchbox button is pressed and searchbox is exposed
        width:100%; // Sharepoint override, stretch the searchbox area full width in mobile

        #SearchBox 
        {
            .ms-srch-sb {
                INPUT {
                    width: $mobile-searchbox-input-width;
                    @include styleguide('searchbox text', false);
                }
            }
        }
    }
    
    @include show-if-viewport-equals($breakpoint-ipad, block);
}