// This mixin provides basic support for CSS3 properties and
// their corresponding experimental CSS2 properties when
// the implementations are identical except for the property
// prefix.


// This mixin is now DEPRECATED
// Use browser-prefix() mixin instead
@mixin experimental($property, $value) {
    -webkit-#{$property} : $value;
    -khtml-#{$property} : $value;
    -moz-#{$property} : $value;
    -ms-#{$property} : $value;
    -o-#{$property} : $value;
    #{$property} : $value; 
}