// TODO: These are not document list specific classes - they apply to table cells

.pm-public-master {
	.ms-vb {
		@include styleguide('p', false);
		A {
			@include styleguide('link', false);
			@extend %pm-link-colors;
		}
	}
	.ms-vb2 {
		@include styleguide('p', false);
	}
	.ms-vh2 {
		A {
			@include styleguide('link', false);
			@extend %pm-link-colors;
		}
	}
	.ms-subtleLink {
		@include styleguide('link', false);
		@extend %pm-link-colors;
	}
}