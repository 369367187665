.container-band {
    width: 100%;
    padding-bottom: 0;
	margin: auto;
    @include respond-to($breakpoint-ipad, false, false) {
        width: 767px;
    } 
    @include respond-to($breakpoint-small-desktop, false, false) {
        width: 970px;
    }

     @include respond-to($breakpoint-desktop, false, false) {
        width: 1170px;
    } 
}