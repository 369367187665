%sharepoint-reset
{
    .ms-webpart-chrome-title {
        margin-bottom:0px; // get rid of space below chrome title
    }

    .js-webpart-titleCell {
        display:inline; // get rio of space below chrome title
        
        .pm-edit-mode & {
            display:block; // don't do this in edit mode, since the webpart editor checkbox wraps
        }
    }

    // Negating display:table that was breaking bootstrap in FF
    .ms-webpart-zone,
    .ms-webpart-cell-vertical,
    .ms-webpart-chrome-vertical {
        display:block;
        UL.cbs-List {
            display:block; // change from table, which forces cbs-List to break out of container
        }
        .ms-noWrap {
            white-space:normal; // forces titles etc to wrap
        }
    }

    // horizontal navigation Edit link
    .ms-core-listMenu-horizontalBox {
        > .ms-core-listMenu-root {
            > .ms-listMenu-editLink {
                margin:0px;
            }
        }
    }

    .main-content
    {
        /* testing */
        float: none; // resets OOTB styling in pagelayouts15.css
        width: auto; // resets OOTB styling in pagelayouts15.css
    }
}