.breadcrumbs {
	padding-top: 15px;
	> SPAN {
		A {
			&:hover {
			}
		}
		&:last-child {
		}
	}

	.pm-breadcrumb-separator {
		background-color: transparent;
		//background-image: url("../images/menu-right.png");
		background-position: 0 2px;
		background-repeat: no-repeat;
		
		display: inline-block;
		height: 13px;
		top: 1px;
		width: 11px;
		vertical-align: top;
	}

	.pm-breadcrumb-separator:before {
		content:'\f105';
		font-family: 'fontawesome';
		font-size: 18px;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		display: inline-block;
		position: relative;
    	left: 3px;
	}

	#DeltaPlaceHolderPageTitleInTitleArea  { // system breadcrumb override styling
		> SPAN {
			> SPAN:nth-child(odd) {
				IMG {
				}
			}

			//when the first child in #delta is a span, then we will not start with a seperator background
			&:first-child {
				> SPAN:nth-child(odd) {
					background-image: none;
					width: auto !important; // sharepoint override
					height: auto !important; // sharepoint override
					top:auto; //
				}				
			}
		}
	}
}