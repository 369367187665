/*
* Sharepoint CSS rules
*/

#s4-bodyContainer {
    padding-bottom:0;
}

/* preserve Sharepoint Suitbar look and feel */

#suiteBar {
  font-family:'Segoe UI', Segoe, Tahoma, Helvetica, Arial, sans-serif;
}

/* Sharepoint Suitbar */
#suiteBar {
  font-size:13px;
}