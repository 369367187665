// this is used to style webparts with display templates that uses UL/LI
// this is not for styling HTML lists in general
// 
// @param @liPaddingDisable - if true, LI is not given bottom padding, which is useful
// in cases where we want elements inside an LI to define vertical spacing

@mixin list($padding:15px, $vertical:false, $liPaddingDisable:false) 
{
	UL {
        display:block;
        list-style:none;

        @if $padding != false {
            padding-bottom:$padding;
        }

        LI {
        	@if $vertical == true {
        		display:block;
        	}
        	@else {
        		display:inline-block;
        	}
            
     		@if $padding != false {
     			padding-bottom:$padding;
                &:last-child {
                    padding-bottom:0;
                }
     		}
            @if $liPaddingDisable == true {
                padding-bottom: 0px;
            }
        }
    }
}