.toc-layout-main {
	> UL {
		> LI {
			margin-bottom: 15px;
			> DIV {
				.headertitle {
					A {
						@include styleguide('toc header', false, $webpart-toc-color);
					}
				}
			}
			UL {
				margin-left: 15px;
				LI.level-section {
					margin: 0;
					&.level-bullet {
						padding-left: 0;
						background-image: none;
					}
					.headertitle {
						A {
							@include styleguide('toc sub', false, $webpart-toc-color);
						}
					}
				}
			}
		}
	}
}