#ms-designer-ribbon {
	
	@include show-if-viewport-equals($breakpoint-ipad, block);
	
	#suiteBar
	{
		#suiteBarLeft
		{
			background-color:$suite-bar-color-bg;
		}

		#suiteBarRight
		{
			background-color:$suite-bar-color-bg;

			#welcomeMenuBox // Welcome dropdown
			{
				.ms-welcome-root {
					> A.ms-core-menu-root {
						color:$suite-bar-link-color;
						&:hover {
							background-color:$suite-bar-hover-color-bg;
							color:$suite-bar-link-hover-color;
						}
					}
					.ms-core-menu-arrow
					{
						IMG 
						{
							background-image: url("../images/spcommon.png");
							padding-left: 1000px;
						}
					}
					&:hover {
						> A.ms-core-menu-root {
							color:$suite-bar-link-hover-color;
						}
					}
				}
			}

			#suiteBarButtons 
			{
				.ms-siteactions-root  // Site Actions dropdown
				{
					.ms-siteactions-imgspan 
					{ // Site Actions icon
						IMG 
						{
							background-image: url("../images/spcommon.png");
							padding-left: 1000px;
						}
					}
				}
				#ms-help // help icon
				{ 
					IMG
					{
						background-image: url("../images/spintl.png");
						padding-left: 1000px;
					}	
				}
			}
		}

		A.ms-core-suiteLink-a
		{
			color:$suite-bar-link-color;
			&:hover {
				background-color:$suite-bar-hover-color-bg;
				color:$suite-bar-link-hover-color;
			}
		}
	}

	@if ($suide-bar-fullscreenmode-hidden == true) {
		#fullscreenmodebox {
			display: none; //by default we want to hide the full screen mode button in ribbon
			.pm-sidenav-exists & {
				display: inline-block;
			}
		}
	}
}

.ms-core-needIEFilter // remove IE8 only Sharepoint filter
{
	#suiteBarRight
	{
		filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
	}
}