$search-results-item-border-color:				inherit;
$search-results-item-hover-color-bg:			inherit;
$search-results-item-link-color:  				$color-primary;
$search-results-item-text-color:				$color-accent-1;

$search-results-item-flyout-color-bg:	 		inherit;
$search-results-item-flyout-border-color:		black;
$search-results-item-flyout-actions-color-bg:	inherit;
$search-results-item-flyout-actions-color:		inherit;
$search-results-item-flyout-actions-color-hover:inherit;

$search-refiner-title-color:					inherit;
$search-refiner-link-color:						inherit;
$search-refiner-link-color-hover:				inherit;

$search-results-wrapper-color-bg:				inherit;
$search-results-wrapper-border-color:			inherit;