.ms-mpSearchBox 
{
	#SearchBox {
		@include respond-to(false, $breakpoint-ipad, false) {
			margin-top: $padding-large;
		}

		.ms-srch-sb {
			position: relative; //allow for search icon to fix to right
		}

		//position search icon to right of search box area
		.ms-srch-sb-searchLink {
			position: absolute;
			top: 2px;
			right: 3px;
		}
	}

	&.mobile {
        #SearchBox {
            .ms-srch-sb {
                INPUT {
                    width: calc(100% - 45px);
                }
            }

            .ms-srch-sb-navLink, .ms-srch-sb-searchLink {
            	display: inline-block;
            }

            .ms-srch-sb-navLink {
            	top: 3px;
            }
        }
    }
}

.ms-srch-sbLarge {
	.ms-WPBody & {
		z-index: 102; //make sure that employee search box in page content is under sticky header
	}
}