// Tree View
.ms-tv-box {

	//in an aqueous left sidebar
	.pm-column-sidebar & {
		margin-left: 0px; //reset margin

		//container that holds a navigation tree
		.ms-navresizeractive-resizetarget {
			width: auto !important; //override the width that SP forces
			max-width: 100%;

			margin-right: 0px !important; //override the margins that SP forces
		}


		//keyword filter menu
		.ms-KFMenu {
			width: auto !important; //override the width that SP forces
			max-width: 100%;

			H3 {
				&.ms-KFLabel {
					min-width: 0px;
					display: block;
				}
			}

			.ms-KFHead {
				padding-left: 5px; //equalize padding
				padding-right: 5px;

				//header titles
				#idKeyFiltersTitle, #idKeyFiltersHeader {
					min-width: 0px;
					display: block;	
				}
			}

			.ms-KFLabelAndBodyContainer {
				padding-left: 5px; //equalize padding
				padding-right: 5px;
			}

			.ms-taxonomy {
				max-width: 100%;
				width: auto;
			}
			.ms-taxonomy-control-holder {
				max-width: 100%;
				width: auto;
			}

			//taxonomy input field
			.ms-taxonomy-fieldeditor {
				width: auto !important; //override the forced width of the input field
			}
		}
	}
}