// Sharepoint's top navigation dropdowns
//
// @param 'activColors' (e.g. active:(blue, black)) - blue text on black background
// @param 'hoverColors'
// @param 'borderColor'
// -----------------------------------------------------------------------------

@mixin top-nav-dropdown($options)
{
	UL.dynamic 
	{
		> LI.dynamic {
			@if map-has-key($options, "activeColors")
			{
				$activeColors: map-get($options, "activeColors");

				> A:hover { //make sure the link is also being hovered to change color
					color: nth($activeColors, 1);
				}

				//@debug $activeColors;
				//@debug $options;
				background-color: nth($activeColors, 2);
			}
		}

		@if map-has-key($options, "borderColor")
		{
			$borderColor: map-get($options, "borderColor");

			@if type-of($borderColor) == color {
				border:1px solid $borderColor;
			}

		}

		> LI.dynamic:hover,
		> LI.dynamic.hover 
		{
			@if map-has-key($options, "hoverColors")
			{
				$hoverColors: map-get($options, "hoverColors");
				background-color: nth($hoverColors, 2);

				> A:hover, > .menu-item:hover 
				{
					color: nth($hoverColors, 1);
				}
			}
		}
	}
}