.pm-slideshow {
    position: relative;
    overflow: hidden;
    width: 100%;
    .pm-slideshow-list {
        position: relative;
        .pm-slideshow-slide {
    	    float: left;
            width: 100%;
            IMG {
                width: 100%;
                display: block;
                margin: 0; // undos sharepoint styling
            }
    	}
    }
    .pm-slideshow-nav {
        .pm-slideshow-next {
            float: right;
        }
        .pm-slideshow-prev {
            float: left;
        }
    }
    .pm-slideshow-anchor-wrap {
        text-align: center;
        margin: 0 auto;
        clear: both;
        position: relative;
        bottom: 0;
        left: 0;
        @include respond-to(480px, false, false) {
            position: absolute;
            bottom: 15px;
            left: 50px;
        }
        .pm-slideshow-anchor {
            padding: 0 5px;
            line-height: 1;
            text-decoration: none;
            @include styleguide('slideshow anchor', false, $slideshow-anchor-color);
            &.active {
                @include styleguide('slideshow anchor', false, $slideshow-anchor-color-active);
            }
        }
    }
}