.pm-public-master 
{ // trigger webpart styling only on 'public' pages
    .main-content { // prevent bleeding into header or footer
        .ms-webpart-chrome 
        {


            .ms-webpart-chrome-title
            {
                H2.ms-webpart-titleText
                {
                }
            }

            .ms-WPBody, .ms-wpContentDivSpace {

                // generic form styling in webparts
                INPUT[type="text"], TEXTAREA {
                }
                INPUT[type="button"] {
                }

                UL {
                    margin-left: 20px; //general ul's found in web parts should have left margin
                }
            }

            .ms-headerSortArrowLink {
            }
        }
    }
}

// webpart styling for all webparts
.ms-webpart-chrome 
{
    .ms-webpart-chrome-title
    {
        H2.ms-webpart-titleText
        {
        }
    }

    .ms-WPBody, .ms-wpContentDivSpace {

        // generic form styling in webparts
        INPUT[type="text"], TEXTAREA {
        }
        INPUT[type="button"] {
        }

        UL {
            .main-content & { //must be in main body
                margin-left: 20px; //general ul's found in web parts should have left margin

                &.dfwp-list {
                    margin-left: 0px; //but not for CQ lists
                }
            }
        }
    }

    .ms-wpContentDivSpace {
        .main-content & { //must be in main body
            position: inherit; //because of inline scrolling of webpart zones in responsive columns, the first relative position must be main body container, so remove from DivSpace when found in main-content
        }
    }

    .ms-headerSortArrowLink {
    }
}