.ms-dialog
{
    //for all dialogs, allow the ribbon to be displayed if needed
    #ms-designer-ribbon {
        display: block;
    }

    // for dialogs using system master page, provide styles for bodyContainer
    &.pm-system-master {
        #s4-workspace 
        {
            background:none; // remove any styling from workspace background
            
            #s4-bodyContainer 
            {
                // hide header, footer, and breadcrumbs inside dialogs

                header {
                    display:none;
                }
                footer {
                    display:none;
                }

                .breadcrumbs {
                    display:none;
                }

                @extend %bootstrap-grid-reset; // reset bootstrap on dialog boxes

                .container { // remove border, box-shadow, etc from container inside dialogs
                    border:0px;
                    @include experimental(box-shadow, none);
                    margin:0px;
                    background:none;
                }

                .ms-breadcrumb-box {
                    display:none; // don't show title/breadcrumb DIV in a dialog
                }
            }
        }
    }
}