// Calls a Google Font by specifying font family, weight, and style
//
// Requires a font-stack map 
// and $fonts map
//
// Globals: $fonts, $font-stack
// Params: 'weight', 'family', 'style'
// ------------------------------------------------------------------------------

@mixin font-stack($options) 
{
    $font-families: map-get($fonts, "font-families"); // global
    $weights: map-get($fonts, "weights"); // global

    $weight: 'Regular';
    $family: $font-stack; // global
    $style: normal;
    
    @if map-has-key($options, 'weight') and map-get($weights, map-get($options, 'weight')) != ''
    {
    	$weight: #{map-get($weights, map-get($options, 'weight'))};
    }

    @if map-has-key($options, 'family') and map-get($font-families, map-get($options, 'family')) != ''
    {
    	$family: #{map-get($font-families, map-get($options, 'family'))};
    }

    @if map-has-key($options, 'style')
    {
        $style: #{map-get($options, 'style')};
    }

    font-family: $family;
    font-weight: $weight;
    font-style: $style;
}