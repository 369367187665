.ms-dialog.pm-system-master
{
    #s4-workspace 
    {
        
        
        #s4-bodyContainer 
        {
            

            header {
                
            }

            .top-footer {
                display: none;
            }
            footer {
               
            }

            .breadcrumbs {
                
            }

            

            .container { 
                
            }

            .ms-breadcrumb-box {
                
            }
        }
    }
}