// Searchbox webpart - NOT the top navigation searchbox
// -----------------------------------------------

.ms-webpartzone-cell {
	#SearchBox {
		.ms-srch-sb-border,.ms-srch-sb-borderFocused
		{
			position:relative; // enables search icon to be absolutely positioned
		}

		INPUT {
			@include styleguide('p', false);
			@extend %reset-sharepoint-input;
		}

		.ms-srch-sb-searchLink
		{
			display: block;
			height: 20px; // search icon size
			width: 20px;
			
			@include position-absolute(3px, 0px, false, false); // position the icon to the right
			
			z-index:999; // layer it on top of input box
			background-color: $searchbox-input-color-bg; // fill background, so text underneath can't be seen

			.ms-srch-sbLarge-searchImg
			{
				&, &:hover {
					@include position-absolute(-57px, false, false, -35px); // CSS sprite positioning
				}
			}
		}
	}
}