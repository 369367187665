
//OSS, simple search and search center search results
.ms-srch-siteSearchResults, .ms-searchCenter-result-main, .ms-searchCenter-main  {

	#SearchBox {
		border: 2px solid $color-primary !important; //overriding resources - need to modify variable used for webpart border-color for this value
		padding: 5px;
		width: 300px;

		@include respond-to($breakpoint-small-desktop, false, false) {
			//max-width: 500px;

		}

		.ms-srch-sbLarge {
			position: relative;

			> INPUT {
				@include border-box();
				display: block;
				float: none;
				height: 30px;
				margin: 0px;
				padding: 0px 30px 0px 7px;
				width: 100%;
			}

			.ms-srch-sb-searchLink {
				position: absolute;
				height: 30px;
				width: 30px;
				right: 5px;
				top: 0px;
				display: block;
			}

			.ms-srch-sbLarge-searchImg
			{
				&, &:hover {
					top: -1px !important; //position sprint, overriding webpart
					left: -53px !important;
				}
			}
		}
	}

	.ms-webpart-chrome {
		//margin-bottom: 20px;

		.ms-WPBody {
			padding: 15px;
			background-color: $search-results-wrapper-color-bg !important;
			border: 1px solid $search-results-wrapper-border-color;
		}
	}


	//when results are in an article column, and thus a page layout, no float necessary
	.pm-column-article & {
		float: none;

		.ms-webpart-chrome {
			margin-bottom: 0;
		}

		.ms-webpartzone-cell {
			margin-bottom: 0;
		}

		 .ms-webpart-chrome .ms-WPBody {
			 padding-top: 0;
		 }

		  .ms-webpart-chrome .ms-WPBody:first-child {
			 padding-top: 15px;
		 }

		#SearchBox {
			@include respond-to($breakpoint-small-desktop, false, false) {
			//	max-width: 100%;
			}
		}
	}
}


.ms-srch-item {
	@include border-box();
	//padding: $padding-xlarge $padding-large $padding-xlarge $padding-large !important; // overwrites our cascading styles - TODO: refactor
	line-height: $article-line-height;
	//margin: 0;
	border-bottom: solid 1px $search-results-item-border-color;

	width: 100%;

	position: relative;

	&:hover {
		background-color: $search-results-item-hover-color-bg;
	}
	.ms-srch-item-link {
		@include styleguide('search result link', false, $search-results-item-link-color);
		line-height: $article-line-height;
		font-weight: bold;
	}
	.ms-srch-item-summary, .ms-srch-item-path {
		@include styleguide('search result text', false, $search-results-item-text-color);
		line-height: $article-line-height;

		display: block; //both shouls appear on their own line
	}

	.ms-srch-item-path {
		color: $color-primary;
	}

	.ms-srch-item-summaryPreview {
		@include border-box();

		width: 100%;
		float: none;
		padding-right: 80px;
	}
	.ms-srch-item-previewContainer {
		float: none;
		width: 100px;
		margin-top: 0px;
		position: absolute;
		top: 15px;
		right: 0px;
	}

	//conversations
	.ms-srch-hover-postPersona {
		position: absolute;
		top: 22px;
		left: 0px;
		float: none;
	}

	.ms-srch-hover-body {
		.ms-srch-hover-postPersona {
			position: initial;
			top: auto;
			left: auto;
			float: left;
		}
	}

	.ms-srch-microblogCommunityItem-body {
		display: block;
		padding-left: 60px;
		padding-right: 85px;
		min-height: 55px;
	}

	.ms-srch-item-metadataContainer {
		position: absolute;
		top: 22px;
		right: 0px;
		float: none;	
	}
}



// results flyout
.ms-srch-hover-outerContainer {
	margin-left: -25px;

	//required to override resources
	#s4-bodyContainer .main-content .container & {
		left: 425px !important;

		@include respond-to($breakpoint-desktop, false, false) {
			max-width: 525px !important;
			left: 550px !important;
		}
	}

	.ms-srch-hover-innerContainer {
		background-color:$search-results-item-flyout-color-bg;
		border-color: $search-results-item-flyout-border-color;
		padding: $padding-medium;
		box-shadow: none;

		max-width: 280px; //must make sure that hover panel is small enough to fit on page without scroll
		@include respond-to($breakpoint-desktop, false, false) {
			max-width: 480px;
		}

		.ms-srch-hover-arrow {
			border-color: transparent $search-results-item-flyout-border-color transparent transparent;
		}
		.ms-srch-hover-content {
			background-color: white;
			.ms-srch-hover-actions {
				background-color: $search-results-item-flyout-actions-color-bg;
				A {
					@include styleguide('results hover action links', false, $search-results-item-flyout-actions-color);
					&:hover {
						@include styleguide('results hover action links', false, $search-results-item-flyout-actions-color-hover);
					}
				}
			}
		}

		.ms-srch-hover-viewerContainer {
			max-width: 240px; //width minus padding;
			max-height: 225px; //width minus padding;

			@include respond-to($breakpoint-desktop, false, false) {
				max-width: 440px; //width minus padding;
				max-height: 425px; //width minus padding;
			}

			> DIV {
				max-width: 240px; //width minus padding;

				@include respond-to($breakpoint-desktop, false, false) {
					max-width: 440px; //width minus padding;
				}
			}
			> IFRAME {
				transform: scale(0.26666666666667, 0.26666666666667) !important; 

				@include respond-to($breakpoint-desktop, false, false) {
					transform: scale(0.36667, 0.445) !important;				
				}
			}

			
		}
	}
}

//general search results
.pm-search-page {
	#s4-bodyContainer {
		overflow: hidden; //make sure that hover panels do not cause scrollbars
	}
	
	#DeltaPlaceHolderMain {
		overflow: visible !important; //must override system overflow


		//for webparts in webpart zones on content result page
		.ms-webpart-chrome {
			.ms-WPBody {
				overflow: visible;
			}
		}
	}

	#SearchBox {
		//padding-bottom: 0px;
	}
}
