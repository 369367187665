//scroll list in a CQB
.pm-cbq-scroll-list {
	position: relative;

	padding: $plugin-scroll-list-wrapper-padding-top $plugin-scroll-list-wrapper-padding-side;

	> UL {
		padding: 0px;
		float: none !important; //has to override many other calls to cbq lists

		z-index: 5;

		> LI {
			padding: 0px;
		}
	}

	.dfwp-item {
		display: none; //by default hide all items

		&.active {
			display: block; //show a particular active item
		}
	}

	.pm-list-next, .pm-list-prev {
		cursor: pointer;
		position: absolute;

		top: $plugin-scroll-list-button-padding-top;
		z-index: 10; //ensure above ul
	}

	.pm-list-next {
		right: $plugin-scroll-list-button-padding-side;
	}
	.pm-list-prev {
		left: $plugin-scroll-list-button-padding-side;
	}
}