// given a variable colors, if it contains a map, and a name of a mixin,
// render the background color using the mixin.

@mixin get-background($colors)
{
	@if type-of($colors) == map // if you send in a map, we trigger a mixin with the map
	{
		@if map-get($colors, mixin) == 'glossy-top-to-bottom-gradient'
		{
			@include glossy-top-to-bottom-gradient(map-get($colors, colors));
		}
	}
	@else {
		background-color: $colors;
	}
}