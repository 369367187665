//apply webpart chrome to all webparts regardless if site or system master page
.main-content { // prevent bleeding into header or footer
    //a webpart cell wrapper
    .s4-wpcell-plain {
        &.hidden {
            display: none !important;
        }
    }

    //the particular chrome of a webpart
    .ms-webpart-chrome 
    {

        @extend %webpart-container;
        width: auto !important; // overrides Sharepoint inline CSS that is triggered by some webparts, e.g. video viewer

        .ms-webpart-chrome-title
        {
            H2.ms-webpart-titleText
            {
                @extend %headerBar;
            }
        }

        .ms-WPBody, .ms-wpContentDivSpace {
            @extend %pm-webpart;
            width: auto !important; // overrides Sharepoint inline CSS that is triggered by some webparts, e.g. video viewer

            // generic form styling in webparts
            INPUT[type="text"], TEXTAREA {
                @include styleguide('p', false);
                padding: $padding-small;
            }
            INPUT[type="button"] {
                @include styleguide('p', false);
                padding: $padding-small $padding-medium;
            }


        }

        .ms-headerSortArrowLink {
            display: inline; // prevent webpart header arrows from wrapping to 2nd line
        }
    }
}