#s4-bodyContainer 
{
	.main-content {
		background-color: $body-color-bg;

		.container {
			padding-bottom: $padding-xlarge; // TODO: abstract this change
			background-color: $main-content-color-bg;
		}
		
		H1[data-name="Page Field: Title"] {
			@include styleguide("h1", true, $style-guide-heading-color-alt);
			display: block; // display initially, individual themes can turn off the page title
		}
	}
}