// bootstrap grid reset
// removes properties to bootstrap elements (col, row, and container)
// used to deactivate bootstrap inside Sharepoint dialogs

%bootstrap-grid-reset
{
	.container 
	{
		width:100%; // bootstrap reset
		padding:0px; // bootstrap reset inside dialog boxes

		.row 
		{
			margin-right:0px;
			margin-left:0px;

			[class^="col-"]
			{
				padding:0px;
			}
		}
	}
}