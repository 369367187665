%top-nav-menu-item-li 
{
	//line-height: #{map-get($navigation-item-height, first)};

	padding-right: $navigation-item-padding;
	padding-left: $navigation-item-padding;
	
	//height: #{map-get($navigation-item-height, first)};
	
	@if $mobile-nav-plugin != false {
		@extend %top-nav-menu-item-li-#{$mobile-nav-plugin}; // use a plugin to configure mobile styling
	}
}