// Content Query webpart reset
// --------------------------------------------

.cbq-layout-main // top level DIV
{ 
	UL.dfwp-column.dfwp-list
	{
		padding:0px;

		LI.dfwp-item
		{
			DIV.item
			{
				padding:0px;
			}
		}
	}
}