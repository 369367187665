// INSTRUCTIONS
//
// @include this mixin directly inside UL.ms-core-listMenu-root
//
// @param (REQUIRED) hoverColors
// @param (REQUIRED) selectedColors
// 
// -----------------------------------------------------------------------------

@mixin top-nav-top-level($options)
{
	// top level hover color styling

	$hoverColors: map-get($options, "hoverColors");
	$selectedColors: map-get($options, "selectedColors");
	$activeColors: map-get($options, "activeColors");
	$padding: map-get($options, "padding");
	$borderColor: map-get($options, "borderColor");

	LI.static
	{
        @include get-background(nth($activeColors, 2));
        
		&.hover
		{
			@include get-background(nth($hoverColors, 2));

			> A.ms-core-listMenu-item:hover, SPAN A.ms-core-listMenu-item:hover
			{
				@include get-background(nth($hoverColors, 2));
				color: nth($hoverColors, 1);
			}
		}

		> A, > SPAN { /** first menu item **/
			&.ms-core-listMenu-item {
				color: nth($activeColors, 1);
                @include get-background(nth($activeColors, 2));
			}
			&:hover,
			&.ms-core-listMenu-selected {
				@include get-background(nth($selectedColors, 2));
				color: nth($selectedColors, 1);
			}
		}

		> UL.static { // each top level menu item container after the first menu item
			> LI 
			{
                @include get-background(nth($activeColors, 2));
                
				> A.menu-item, > SPAN.menu-item {
					color: nth($activeColors, 1);
                    
				}

				@if type-of($borderColor) == color
				{
					> A, > SPAN {
						> SPAN.additional-background {
							border-left: 1px solid $borderColor;
							padding-left: $padding;
							padding-right: $padding;
						}
					}
				}
			}
			
			> LI.selected
			{
				@include get-background(nth($selectedColors, 2));

				> A.menu-item, > SPAN.menu-item {
					color: nth($selectedColors, 1);
				}
			}
			> LI:hover, 
			> LI.hover-off,
			> LI.hover { // Top level menu item
				@include get-background(nth($hoverColors, 2));

				> A.menu-item:hover, > SPAN.menu-item:hover {

					color:nth($hoverColors, 1);
				}
			}
		}
	}
}