.pm-edit-mode 
{
    @include hide-font-menu(); // hide font menu in Edit Mode ribbon

    #s4-bodyContainer 
	{
		.main-content {
			TABLE#MSOTlPn_WebPartPageDiv {
			    table-layout:fixed; // when editing a webpart, constrain elements from breaching container (IE slideshow)
			} 

			.ms-webpart-chrome-title {
				H2.ms-webpart-titleText {
					background:$pm-edit-mode-header-color-bg;
					color:$pm-edit-mode-header-color;
				}
			}

			.ms-webpart-chrome-title {
				.js-webpart-titleCell {
					display:inline-block;
				 }
			}

			.pm-article-content {
				.pm-article-image {
					float: none;
					margin: 0;
				}
			}
		}
	}
}