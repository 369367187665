// Controls a palette of fonts
// -----------------------------------------------------------------

@mixin styleguide($html, $margin:false, $color:false)
{
    @if $margin == true {
        margin:0px 0px 5px 0px; // TODO: Need the margin to be customizable
    }

    $style: map-get($style-guide, $html);
    $defaults: map-get($style-guide, defaults);
    $family: null;

    @if type-of($style) == map
    {
        $size: map-get($style, "size");

        @if map-has-key($style, "family") {
            $family: map-get($style, "family");
        }
        @else {
            $family: map-get($defaults, "family");
        }

        @include font-stack ((font-family: $family, style: map-get($style, 'style'), weight: map-get($style, 'weight')));

        @if(map-has-key($style, "text-transform")) {
            text-transform: #{map-get($style, "text-transform")};
        }

        @if(map-has-key($style, "color")) {
            color: #{map-get($style, "color")};
        }
                
        @if type-of($size) == map {
            @if map-has-key($size, 'mobile') {
                font-size: #{map-get($size, 'mobile')};
            }

            @if map-has-key($size, "ipad") {
                @include respond-to($breakpoint-ipad, false, false) {
                    font-size: #{map-get($size, 'ipad')};
                }
            }
            @if map-has-key($size, "desktop") {
                @include respond-to(1148px, false, false) {
                    font-size: #{map-get($size, 'desktop')};
                }
            }
        }
        @else {
            font-size: $size;
        }
    }

    @if type-of($color) == color
    {
        color: $color;
    }
}