//site settings
.ms-siteSettings-root {
    
    //less code required to make site settings desktop first, so for mobile apply new stlying
    @include respond-to(false, $breakpoint-ipad, false) {
        > TABLE {
            display: block;
            
            > tbody {
                display: block;

                > tr {
                    display: block;

                    > td {
                        display: block;
                    }
                }
            }
        }
    }

    //site settings page primary columns
    .ms-linksection-columnBox {

        //less code required to make site settings desktop first, so for mobile apply new stlying
        @include respond-to(false, $breakpoint-ipad, false) {
            display: block;
            padding: 0px;
        }
    }
}