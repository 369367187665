#ms-designer-ribbon {
	
	
	#suiteBar
	{
		#suiteBarLeft
		{
		}

		#suiteBarRight
		{

			#welcomeMenuBox // Welcome dropdown
			{
				.ms-welcome-root {
					> A.ms-core-menu-root {
						&:hover {
						}
					}
					.ms-core-menu-arrow
					{
						IMG 
						{
						}
					}
					&:hover, &.ms-welcome-hover {
						> A.ms-core-menu-root {
							color:$suite-bar-link-hover-color;
						}
					}
				}
			}

			#suiteBarButtons 
			{
				.ms-siteactions-root  // Site Actions dropdown
				{
					.ms-siteactions-imgspan 
					{ // Site Actions icon
						IMG 
						{
						}
					}
				}
				#ms-help // help icon
				{ 
					IMG
					{
					}	
				}
			}
		}

		A.ms-core-suiteLink-a
		{
			&:hover {
			}
		}
	}
}

.ms-core-needIEFilter // remove IE8 only Sharepoint filter
{
	#suiteBarRight
	{
	}
}

//drop down menus in ribbon
.ms-cui-menu32 {
	background-color: #ffffff; //make the background color of ribbon dropdown white
}