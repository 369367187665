FOOTER, .top-footer {
	overflow-x: hidden; //removing the scrollbar
	color: white;
	padding-top: 15px;
	padding-bottom: 15px;
	A {
		color: white;
	}
	
	.container-band {
		//width: 1170px;
		//margin: auto;
		//When specified as fluid
		.pm-fluid & {
			@include respond-to($breakpoint-ipad, false, false) {
				width: 100%;
			}
		}
		UL {
			list-style: none;
		}
		.footer-heading, .footer-links {
			font-size: $font-size-normal;
			line-height: 1.5em;
			padding-top: 5px;
			padding-bottom: 5px;
		}

		.footer-heading {
			padding-bottom: 10px;
		}
		.pm-copyright
		{
			float: none;
			clear: both;
			text-align: center;
		}
	}
}

.top-footer {
	background-color: $color-accent-2;
}

.bottom-footer {
	color: white;
	background-color: $color-terciary;
	text-align: center;
	LI {
		display: inline;
		padding-right: 5px;
	}
	LI:after {
		content: ' | ';
		position: relative;
    	left: 5px;
		
	}
	LI:last-child:after {
		content: ' ';
	}
	A {
		color: white;
	}
}

.footer-heading {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 18px;
}

.how-do-i {
	position: relative;
    top: 10px;
}

.how-do-i:after {
	content: "\f059";
	display: inline-block;
	font-family: FontAwesome;
	font-size: 18px;
	font-weight: bold;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	position: relative;
    left: 5px;
}



.footer-social-links {
	padding-bottom: 10px;
	LI {
		   // background-color: white; //creating a circle background around the icon
			//border-radius: 50%; 
			height: 26px;
			width: 26px;
			display: inline-block;
			margin-right: 5px;
	}

	.social-facebook {
		background-color: $color-accent-10;
	}

	.social-twitter {
		background-color: $color-accent-11;
	}

	.social-flickr {
		background-color: #888;
	}

	.social-vimeo {
		background-color: #8dd0de;
	}

	.fa {
		color: white;;
		font-size: 16px;
		position: relative;
		left: 7px;
    	top: 5px;
	}

	.fa-vimeo {
		left: 4px;
	}
}