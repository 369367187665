#DeltaTopNavigation.ms-core-navigation 
{
	@extend %navbar-chunky;
	
	@extend %mobile-nav-absolute; // in mobile, absolutely position header, so mobile dropdown opens on top of content
	@include respond-to(false, $breakpoint-ipad, false)
	{
		margin-top:$padding-medium; // push the nav down to the bottom edge of header
		padding-bottom:$padding-medium; // add padding and background color to bottom of mobile nav dropdown
		background-color:#{nth(map-get(nth($map-nav-mobile, 1), activeColors), 2)};
	}

	//by default, hide the managed metadata edit button as in responsive design causes issues
	.ms-listMenu-editLink, LI.ms-listMenu-editLink {
		display: none;
	}

	.ms-core-listMenu-horizontalBox {
		float: right;
		text-transform: uppercase;
		UL.ms-core-listMenu-root > LI.static:first-child > A { //this hides the root home page
			//display: none;
			.menu-item-text {
				display: none;
			}
			.ms-navedit-flyoutArrow:before {
				content: "\f015";
				display: inline-block;
				font-family: FontAwesome;
				font-size: 18px; //trying to look more like mockup
				//font-weight: bold;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
			
			}

			.ms-navedit-flyoutArrow:after {
				display: none;
			}
		}
		.ms-navedit-flyoutArrow:after {
		    content: "\f107";
			display: inline-block;
			font-family: FontAwesome;
			font-size: 16px;
			font-weight: bold;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			position: relative;
			left: 5px;
			top: 2px;
		}

		.menu-item-text {
			font-size: 18px;
			font-family: $font-stack;
		}
	}
}