.pm-section-band {
  //  padding-left: 0;
  //  padding-right: 0; //overriding bootstrap to go 100% full width;
    background-color: $color-accent-3;

    .container {
     //   background-color: white !important; //todo: update styling so framework styling doesn't override
    }
}

    .block-icon {
        width: 70px;
        height: 70px;
        border-radius: 40px;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        text-align: center;
        font-size: 30px;
        color: #fff;
        line-height: 70px;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        background-color: $color-accent-13;
        box-shadow: 6px 6px 8px -5px #666;
    }

     .main-content .pm-news-band .ms-webpart-chrome .ms-webpart-chrome-title H2.ms-webpart-titleText {
       text-align: center !important; //need to override inline sharepoint javascript
       color: $color-accent-5;
       font-weight: bold;
       margin-top: 10px;
     }

     .main-content .pm-news-band .ms-webpart-chrome .ms-wpContentDivSpace {
         padding-top: 5px; //overriding 15px padding typically have between chrome title and content
     }

     .pm-news-band {
        top: -29px;
        position: relative;

        .pm-news-links {
            color: $color-primary;
            font-size: 16px;
            line-height: 1.65em;
            margin-left:15px;
        }

        .item { //summary links styling;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 30px;

            A {
                margin-left: 15px;
                color: $color-primary;
            }

            A:before {
                content: "\f105";
                display: inline-block;
                font-family: FontAwesome;
                font-size: 18px;
                font-weight: bold;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                position: relative;
                right: 7px;
                color: $color-accent-16;
            }
        }

        .pm-news-links:before {
            content: "\f105";
            display: inline-block;
            font-family: FontAwesome;
            font-size: 18px;
            font-weight: bold;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            position: relative;
            right: 7px;
        }
     }