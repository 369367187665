%pm-webpart-base
{
    padding:15px;
}

%pm-webpart
{
	@extend %pm-webpart-base;

	background-color:$webpart-color-bg;

    &.ms-WPBorder {
        
    }
}
