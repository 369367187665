// Content Query webpart reset
// --------------------------------------------

.cbq-layout-main // top level DIV
{ 
	@include list($padding-large, true, true); // adds padding to the list
    
    // TODO: abstract some of the structural changes
    
	UL.dfwp-column.dfwp-list
	{
		padding: 0 $padding-large;
		@include border-box();
		@include respond-to(false, 767px, true) { // need max width so we don't overwrite styling in desktop
			width: 100% !important; // overwrites inline styling - this styling prevents grouped items from colliding
		}
		LI.dfwp-item
		{
			> DIV {
				@include styleguide('cq text', false, $webpart-color);
				@extend %clearfix; // clears floated images
				> A, H2, H2 A, .link-item > A{
					@include styleguide('cq title', false, $cq-link-color);
					&:hover {
						@include styleguide('cq title', false, $cq-link-color-hover);
					}
				}
				.link-item-large > A {
					@include styleguide('cq title large', false, $cq-link-color);
					&:hover {
						@include styleguide('cq title large', false, $cq-link-color-hover);
					}
				}
				.pm-byline { // CUSTOM 
					margin-bottom: $padding-small;
					@include styleguide('cq byline', false, $cq-byline-color);
				}
				.pm-date { // CUSTOM 
					@include styleguide('cq date', false, $cq-date-color);
				}

				DIV[class^="image-area-"], IMG.image {
					max-width: 100%;
					padding: 0;
				}
				.description {
					@include styleguide('cq text', false, $webpart-color); // need to declare font styling here as well for certain ootb itemstyles
					margin-bottom: $padding-large;
					P {
						margin: 0px 0px $padding-medium 0px;
						line-height: 1.2;
						&:last-child {
							margin-bottom:0px;
						}
					}
				}

				A.pm-see-more-link { // CUSTOM 
					@include styleguide('cq see more', false, $cq-see-more-color);
					margin: $padding-small 0;
					display: block;
				}

				&.groupheader {// styles group header, if present
					@include styleguide('cq title', false, $cq-link-color);
				}
				&.bullet { // places bullet back in just on this itemstyle
					padding: 0 0 0 $padding-medium;
				}

				&.title-With-Background {
					background-color: $cq-title-with-background-color-bg;
					> A {
						@include styleguide('cq title', false, $cq-title-with-background-color);
						&:hover {
							@include styleguide('cq title', false, $cq-title-with-background-color);
						}
					}
				}
				
				&.DateLeft { // custom xsl item template
					.pm-date {
						width: 60px;
						height: 60px;
						margin: 0 auto;
						text-align: center;
						padding: $padding-medium;
						background: $webpart-date-color-bg;
						@include border-box();
						@include styleguide('cqw date left', false, $webpart-color);
					}
				}
				&.pm-accordian-cq {
					.pm-accordian-label {
						cursor: pointer;
					}
					.pm-accordian-content {
						margin-bottom: $padding-large;

					}
				}
				&.pm-scroll-list {
					.pm-list-next {
						cursor: pointer;
					}
					.pm-list-prev {
						cursor: pointer;
					}
				}

				&.pm-scroll-list {
					.pm-list-next {
						cursor: pointer;
					}
					.pm-list-prev {
						cursor: pointer;
					}
				}
			}
		}
	}
}
