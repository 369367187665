.pm-read-more {
    float: right;
    font-size: 14px;
    text-transform: uppercase;
    color: $color-primary;
    font-weight: bold;
    A {
        color: $color-primary;
    }

    A:after {
        content: "\f101";
        display: inline-block;
        font-family: FontAwesome;
        font-size: 16px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        position: relative;
        left: 5px;
        
    }
}