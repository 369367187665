// Content Query webpart reset
// --------------------------------------------

.cbq-layout-main // top level DIV
{ 
	@include list($padding-large, true, true); // adds padding to the list
    
    // TODO: abstract some of the structural changes
    
	UL.dfwp-column.dfwp-list
	{
		LI.dfwp-item
		{
			DIV.item {
				@include styleguide('cq text', false, $webpart-color);
				@extend %clearfix; // clears floated images
				> A, H2, H2 A {
					@include styleguide('cq title', false, $cq-link-color);
					&:hover {
						@include styleguide('cq title', false, $cq-link-color-hover);
					}
				}
				H2 {
					margin-bottom: 8px;
				}
				.pm-byline {
					margin-bottom: $padding-small;
					@include styleguide('cq byline', false, $cq-byline-color);
				}
				.pm-date {
					@include styleguide('cq date', false, $cq-date-color);
				}
				.description {
					margin-bottom: $padding-large;
					P {
						margin: 0px 0px $padding-medium 0px;
						line-height: 1.2;
						&:last-child {
							margin-bottom:0px;
						}
					}
				}
				
				@include image-bordered($webpart-image-color-border);

				IMG {
					width: 100px;
				}
				A.pm-see-more-link { // CUSTOM 
					@include styleguide('cq see more', false, $cq-see-more-color);
					@extend %pm-link-colors;
				}

				&.groupheader {// styles group header, if present
					@include styleguide('cq title', false, $cq-link-color);
				}
			}
		}
	}
}
