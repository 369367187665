// centers an image below a certain viewport
// 
// Note: container must be display block for this to work.
// if width is set (e.g. 66.6666%) it can center the image
// Apply this code to the container wrapping the image you want to center if width
// is a concern

@mixin center-image-below-viewport($breakpoint, $width:false)
{
	@include respond-to(false, $breakpoint, false) {
		margin: 0 auto;
		display: block;

		@if $width != false {
			width: $width;
		}
		
		IMG { // apply to image inside the container also if there is one
			margin: 0 auto;
			display: block;
		}
	}
}