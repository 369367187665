// Sharepoint Logo snippet styling

#DeltaSiteLogo { // Sharepoint Logo snippet
	@include center-image-below-viewport($breakpoint-ipad, 66.6666666%); // center in mobile
	
	.ms-siteicon-a {
		max-width:100%; // resets Sharepoint width so it doesn't break out of container
		@include center-image-below-viewport($breakpoint-ipad); // center in mobile

		.ms-siteicon-img {
			max-width:100%; // resets Sharepoint width
			max-height:initial; // resets Sharepoint height
		}
	}
}

.pm-logo { // hard-coded logo element - may not always be used
	@include center-image-below-viewport($breakpoint-ipad, 66.6666666%);
}