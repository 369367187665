.container { // system page reset
	margin-top:0px;
	margin-bottom:0px;
	background-color:transparent;
	color:inherit;
	border:0px;
}

// TODO: restructure .pm-system-master to the HEAD
// Turn the 1170px fix into a class and include optionally based on config setting
// Slowly include some of the rules below
// May need to create a V2 instead of modiying this file, for backwards compatibility

.pm-system-master {
	#s4-bodyContainer {
		.main-content {

			.table-cell.right-column // Design Manager bullet list needs spacing
			{
				UL {
					margin:13px 0px;
					padding-left:40px;
				}

				.ms-core-menu-box
				{ // resets the Design Manager spacing rules
					UL {
						margin:0px;
						padding-left:0px;
					}
				}
			}


			.ms-heroCommandLink:hover {
				.ms-list-addnew-imgSpan20 {
					> IMG {
						background-image: url("../images/spcommon.png");
						padding-left: 1000px;
					}
				}
			}

			.ms-WPBody {
				background-color:inherit; // override custom color inside system pages
			}

			.pm-column-article { // (optional plugin) - on overflow, create scrolling
				position:static; // disabled for POLA
				overflow:auto; // disabled for POLA
				TABLE.ms-core-tableNoSpace {
					* {
						white-space:normal;
					}
					TD[nowrap=nowrap] * {
						white-space:nowrap;
					}
				}
				TABLE.ms-formtoolbar {

				}
			}
		}
	}
}

#s4-bodyContainer {
	header.pm-system-master {
		.container {
			//width:100%; // optional fix where system pages are 100% width
			.logo {
				left:-15px;
			}
		}
		.main-nav {
			margin-left: -15px;
		}
	}
	.main-content.pm-system-master {
		.container {

			font-size:$system-font-size-normal;
			//width:100%; // optional fix where system pages are 100% width

			.ms-metadata, .ms-descriptiontext, .ms-secondaryCommandLink {
				font-family:$font-stack;
			}

			.ms-core-pageTitle, .ms-core-pageTitle a {
				font-family:$font-stack;
			}



			ARTICLE { // left column general styling
				a {
					@extend %pm-link-colors;
				}
				.ms-breadcrumb-box
				{
					@extend %headerBar;
					padding:0px;
					display:block;

					H1.ms-core-pageTitle#pageTitle
					{
						color:white;
						padding-left:15px;
						A {
							color:white;
						}
						#DeltaPlaceHolderPageTitleInTitleArea
						{
							> SPAN {
								> SPAN {
									top:3px; // right arrow divider image position
								}
							}
						}
					}
				}

				[data-name='ContentPlaceHolderMain']
				{
					@extend %pm-webpart;
				}
			}

			.pm-column-article { // (optional plugin) - on overflow, create scrolling
				//position:static; // disabled for POLA
				//overflow:auto; // disabled for POLA
				TABLE.ms-core-tableNoSpace {
					* {
						white-space:normal;
					}
					TD[nowrap=nowrap] * {
						white-space:nowrap;
					}
				}
				TABLE.ms-formtoolbar {

				}
			}
		}
	}
}