UL#Paging {
	@extend %pagination;
	.ms-srch-result & {
		@extend %pagination;
	}
}

.pm-people-directory-contacts {
	.ms-srch-Paging-Container {
		padding: 15px;
		margin-left: 15px;
		margin-bottom: 15px;
	}
}

#Paging {

	.pagerMoveToFirst::before {
	 	content: "\e929"; //double arrow
		font-family: 'icomoon';
		font-size: 16px;
		color: $color-primary;
		position: relative;
		top: 6px;
		left: 5px;
	}

	.pagerMoveToLast::before {
		content: "\ea26"; //double arrow
        font-family: 'icomoon';
		font-size: 16px;
		color: $color-primary;
		position: relative;
		top: 6px;
		left: 5px;
	}
}

.ms-srch-result #Paging {
	text-align: initial;
}