/* RESET */

body {
    //font-size:10px;
}

UL { 
    margin: 0; 
    padding: 0;
}

TEXTAREA {
    resize:vertical;
}