// Optional styling used to indent secondary nav items in mobile dropdowns
// -------------------------------------------------------------------------------------

%indent-secondary-nav
{
	@include respond-to(false, $breakpoint-ipad, false)
	{
		// CSS to use if we disable background colors for secondary mobile nav
		// Also won't work if we have borders between each item

		//UL.dynamic {
		//	margin-left:$padding-large;
		//}

		// Secondary mobile nav indentation - works to 3rd level
		// Use this if we want to apply different background color to 2nd+ level
		
		LI.static.dynamic-children { // 1st level, dynamic-children means it has child nodes
			> UL.dynamic { // dropdown DOM
				> LI { // 2nd level
					text-indent:$padding-large;

					> UL.dynamic {  // dropdown DOM
						> LI { // 3rd level
							text-indent:$padding-large * 2;

							> UL.dynamic {  // dropdown DOM
								> LI { // 4th level
									text-indent:$padding-large * 3;

									> UL.dynamic {  // dropdown DOM
										> LI { // 5th level
											text-indent:$padding-large * 4;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}