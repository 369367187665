// Content section on our Article Page Layout
// -----------------------------------------------------------------------------

.pm-article-content {
	background-color: $article-color-bg;
	.pm-article-image {
		float: left;
		IMG {
			margin: 0 15px 5px 0;
		}
		P {
            margin: 0; // remove margin under image caption
		}
	}
	.pm-article-summary-links {
		@include styleguide('article summary links', false);
	}
	.pm-article-byline {
		@include styleguide('article byline', false);
	}
	.pm-article-date {
		@include styleguide('article date', false);
	}
}
