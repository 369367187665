.pm-edit-mode 
{
  

    #s4-bodyContainer 
	{
		.main-content {
            overflow-x: auto;
			
            TABLE#MSOTlPn_WebPartPageDiv {
                   
                } 

                .ms-webpart-chrome-title {
                    H2.ms-webpart-titleText {
                    }
                }

                .ms-webpart-chrome-title {
                    .js-webpart-titleCell {
                      
                    }
                }

                .pm-article-content {
                    .pm-article-image {
                     
                    }
                }
            }
        }
    }