// List view table webpart styling
// -----------------------------------------------------------------------------

.ms-listviewtable {
    IMG {
        max-width:100%; // prevent image from breaking the container
		
		&.ms-spimn-img {
			max-width:none; // box icon on Master Page Gallery table
		}
		&.ms-ellipsis-icon {
			max-width:none; // box icon on list view table
		}
		&.js-ellipsis25-icon {
			max-width:none; // box icon on list view properties panel
		}
		&.js-callout-closeButtonImage {
			max-width:none; // close icon on list view properties panel	
		}
		&.ms-selectitem-icon, &.ms-selectall-icon {
			max-width:none; // check icon on list view table
		}
		&.ms-ellipsis-icon-tile {
			max-width:none; // ellipsis on image library hover
		}
		&.jsgridcluster_editheader {
			max-width:none; // pencil
		}
    }

    //list view tiles

    //a tile's root wrapper
    .ms-tileview-tile-root {
    }

    //tile's inner wrapper
    .ms-tileview-tile-inner {
    }

    //tile's actual content block, at same level as .ms-tileview-tile-tabularBox
    .ms-tileview-tile-content {
    }

    //in a list view page with tiles, the overlay buttons on a given image tile
    .ms-tileview-tile-tabularBox {
    	position: inherit;
    	top: 0px;
    	margin-top: -27px;
    }
}