// Hides the FONT, FONT SIZE, FONT COLOR, and HIGHLIGHT options in the ribbon on edit mode
// This works in page content and web part edit modes

$is-font-menu-active: true !default;

@mixin hide-font-menu() {
	@if $is-font-menu-active == true {
		#Ribbon\.EditingTools\.CPEditTab\.Font-Large-0-0, 
        #Ribbon\.EditingTools\.CPEditTab\.Font\.FontColor-Small, 
        #Ribbon\.EditingTools\.CPEditTab\.Font\.FontBackgroundColor-Small {
			display: block; 
		}
	} @else {
		#Ribbon\.EditingTools\.CPEditTab\.Font-Large-0-0,
        #Ribbon\.EditingTools\.CPEditTab\.Font\.FontColor-Small,
        #Ribbon\.EditingTools\.CPEditTab\.Font\.FontBackgroundColor-Small {
			display: none;
		}
	}
}