// Style Guide
// -------------------------------------------

$style-guide-color:				$color-accent-1; // style guide default font color
$style-guide-color-alt:			$color-primary;
$style-guide-heading-color:		$color-primary;
$style-guide-heading-color-alt:	$color-terciary;
$style-guide-accent-1:			$color-accent-7;
$style-guide-accent-2:			$color-accent-8;
$style-guide-references:		$color-accent-9;
$style-guide-quote:             $color-primary;
$style-guide-emphasis:          $color-primary;
$style-guide-button:			$color-accent-1;
$style-guide-button-bg:			$color-secondary;
$style-guide-button-border:		black;