// Wireframe
// assumes bootstrap 3

BODY { // ios smooth scrolling needs to be applied to BODY to work
    @extend %ios-scrolling;
}

#s4-bodyContainer 
{
	@extend %sharepoint-reset;
    @include peg-width($peg-width);
    @extend %optimize-text;

	.main-content {
		.container {

			
		}
	}
}

// hide OBJECT ID=application/x-sharepoint-uc in the footer, which creates a white space underneath footer
OBJECT#application\/x-sharepoint-uc {
	display:none;
}

.pm-no-ribbon { // when ribbon is hidden, make sure that the body has vertical scrollbar
        body {
            overflow:auto;
        }
}