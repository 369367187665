// Content Editor styling
// Font definitions should be placed in style-guide.scss
//
// -----------------------------------------------------------------------------

.ms-WPBody { //limit to only when in a webpart
	.ms-rtestate-field {
		P {
			line-height: 1.2;
		}
	    EMBED, IFRAME, IMG {
			max-width: 100%; // prevent iframes and embedded video from overflowing content editor field
		}
		IMG {
			margin:$padding-medium;
			@include border-box(); // keep image and margin inside web part borders
			&.ms-rtePosition-1 { // image position: left
				margin-left: 0; // remove left margin, so image aligns to left side
			}
			&.ms-rtePosition-2 { // image position: right
				margin-right: 0; // remove right margin, so image aligns to right side
			}
		}
		
		@include image-bordered($webpart-image-color-border);
		
	    UL {
	        @include styleguide("p");
	        @extend .styledList;
	    }
	}
}