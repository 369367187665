// Holiday Calendar webpart

.ms-acal-header, .ms-acal-rootdiv {
	@include styleguide('p', false);
}

.ms-acal-item
{
    background-color: $webpart-calendar-item-color-bg;
    border: solid 1px $webpart-calendar-border-color;
    line-height: $webpart-calendar-line-height;

    @include styleguide('p', false, $webpart-calendar-item-color);

    &.ms-acal-item-default-hover {
        border: solid 1px $webpart-calendar-border-color;
    }
}

.ms-acal-today {
    border-top: solid 1px $webpart-calendar-border-color !important; // sharepoint override
    color: $webpart-calendar-today-color !important; // sharepoint ovverride
}	

.ms-acal-vlink {
	A[evtid="new_item"] {
		@include styleguide('link', false);
		@extend %pm-link-colors;
	}
}

.ms-calhead { // table head - mobile version
    text-align: center; // center arrows and month
}