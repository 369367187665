.breadcrumbs {
	@include styleguide('breadcrumb', false, $breadcrumb-color);
	padding: 0 0 $padding-medium 0;
	> SPAN {
		A {
			@include styleguide('breadcrumb', false, $breadcrumb-color);
			&:hover {
				@include styleguide('breadcrumb', false, $breadcrumb-hover-color);
				text-decoration: none;
			}
		}
		&:last-child {
			@include styleguide('breadcrumb', false, $breadcrumb-current-page-color);
		}
	}

	/*default breadcrumb styling*/
	.pm-breadcrumb-separator {
		position: relative;
	}

	#DeltaPlaceHolderPageTitleInTitleArea  { // system breadcrumb override styling
		> SPAN {
			> SPAN:nth-child(odd) {
			//	background-image: url('../images/menu-right.png');
				background-repeat: no-repeat;
				background-position: center;
				width: 11px !important; // sharepoint override
				height: 13px !important; // sharepoint override
				top:2px; // vertically center the image
				IMG {
					display: none;
				}
			}
		}
	}
}