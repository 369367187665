// Creates a triangle with a specified width, color, and pointing direction

 @mixin carrot($dir:"top", $color:$color-primary, $width:5px)
{
	content: " ";
	width: 0;
	height: 0;
	@if $dir == "top" {
		border-left: solid $width transparent;
		border-right: solid $width transparent;
		border-bottom: solid $width $color;
	}
	@else if $dir == "bottom" {
		border-left: solid $width transparent;
		border-right: solid $width transparent;
		border-top: solid $width $color;
	}
	@else if $dir == "right" {
		border-top: solid $width transparent;
		border-bottom: solid $width transparent;
		border-left: solid $width $color;
	}
	@else if $dir == "left" {
		border-top: solid $width transparent;
		border-bottom: solid $width transparent;
		border-right: solid $width $color;
	}
}