// Instructions
// 
// Colors are in pairs: (font color, background color)
// borderColor requires one color, not a pair.

$map-nav: (
    ( // first level
        hoverColors:                ($color-terciary, transparent),
        activeColors:               ($color-accent-4, transparent),
        selectedColors:             (inherit, transparent),
        padding:                    $navigation-item-padding,
        height:                     30px
    ),
    ( // dropdown
        activeColors:               ($color-accent-4, $color-accent-7),
        hoverColors:                (inherit, $color-accent-4),
		borderColor:                $color-accent-5,
        height:                     30px
    ),
);

$map-nav-mobile: (
    ( // first level
        hoverColors:                ($color-terciary, transparent),
        activeColors:               ($color-accent-4, white),
        selectedColors:             (inherit, transparent),
        height:                     50px
    ),
    ( // dropdown
        activeColors:               ($color-accent-4, $color-accent-7),
        hoverColors:                (inherit, $color-accent-4),
        height:                     50px
    ),
);