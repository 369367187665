/*Search refiner for search results page*/

.ms-ref-refiner { // search refiner
	float: none;
	padding: 0; 

	&:first-of-type {
		#Container {
		}
	}

	/*#Container ID provided by SharePoint*/
	#Container {
		padding-bottom: 0;
		text-indent: 5px;
		border-top: 0px solid $search-refiner-border-color;
		border-left: 0px solid $search-refiner-border-color;
		border-right: 0px solid $search-refiner-border-color;
		border-bottom: 0px solid $search-refiner-border-color;
		

		.ms-ref-refinername { // refiner heading
			width: auto;
			padding-top: 7px;
		}
		> DIV { // refiner content
			background-color: $search-refiner-color-bg;
			> DIV {
			//	text-indent: 10px;
			}
			> A { // show more link
				text-indent: 10px;
			}
			.histogram_container {
				padding-top: 10px;

				padding-left: 10px;
				@include respond-to(768px, false, true) {
					padding-left: 0;
				}
			}
		}
	}
	@include respond-to(768px, false, true) {
	}
}