$navigation-item-width:					170px;
$navigation-item-padding:				$padding-medium;
$navigation-expand-arrow-width:			5px;
$navigation-expand-arrow-color:   		black;

$navigation-border-color:				black;

// Navbar Chunky (optional)
$mobile-navigation-border-color:		black;
//$mobile-navigation-expand-padding:		-35px;
