.pm-public-master 
{
	.main-content 
	{
		.ms-webpart-chrome 
		{
			.ms-wpContentDivSpace
			{
				.ms-InlineSearch-DivBaseline // search box wrapper - in Pages App web part
				{
					INPUT[type="text"] 
					{
						padding: 0;
					}
				}
			}
		}	
	}
}