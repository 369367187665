.pm-system-master
{
	#s4-bodyContainer
	{
		.main-content
		{
			#sideNavBox
			{
				

				@include respond-to(768px, false, true) {
					
				}

				.ms-core-navigation 
				{
					.ms-core-listMenu-verticalBox // vetical navigation/quicklaunch
					{ 
						
						display: block; // display for mobile+
						
						@include respond-to(768px, false, true) {
							 
						}

						UL {
							
						}
						> .ms-core-listMenu-root {
							> li {
								> .ms-core-listMenu-item {
									
									
								}
								> .ms-core-listMenuEdit {
									
								}

							}
						}
						
					}
					.ms-splinkbutton-text {
						
					}
				}
			}
		}
	}
}
