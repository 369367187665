// optional header searchbox styling
// 
// in this case, show the dropdown and search icon inside the searchbox

.ms-mpSearchBox // header searchbox wrapper class
{
	background-color: $searchbox-color-bg; // put background color behind the UI
	
	#SearchBox
	{
		.ms-srch-sb-navLink,
		.ms-srch-sb-searchLink
		{
			display:none; // don't show in mobile
			@include respond-to($breakpoint-ipad, false, false) 
			{
				display: inline-block;
				position: relative;
				top: 3px;
			}
		}
	}
}