// Experimental system master page fix for quick launch
// we'll move this to resources once its 'stable'

.pm-system-master
{
	#sideNavBox
	{
		.ms-core-sideNavBox-removeLeftMargin {
			margin-left: 0; // override Sharepoint CSS that was moving quick launch too much to the left
		}
	}
}