HEADER
{
	padding-bottom: 0;
	margin-bottom: 0;
	&.fixedHeader {
	//	@extend %fixedHeader;
	}

	.container {
		//When specified as fluid
		.pm-fluid & {
			@include respond-to($breakpoint-ipad, false, false) {
				width: 100%;
			}
		}	
	}

	.nav-wrapper {
		background-color: transparent; 
		padding-top: 0;
		padding-bottom: 0;

		@include respond-to($breakpoint-ipad, false, false) {
			background-color: $color-secondary;
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}
	#DeltaSiteLogo {
		display: inline-block;
		height: 95px; //this may change depending on actual logo
   	 	//padding-bottom: 10px;
	}

	.search-wrapper {
		.ms-mpSearchBox {
			width: 330px;
			position: relative;
    		top: -47px;
			#SearchBox {
				.ms-srch-sb-searchLink {
					background-color: white;
					top: -5px;
    				right: -5px;
    				padding: 8px;
					IMG {
						display: none;
					}
				}
				.ms-srch-sb-searchLink:before {
					content: "\f002";
					display: inline-block;
					font-family: FontAwesome;
					font-size: 16px;
					font-weight: bold;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					color: $color-accent-4;
					position: relative;
					left: 5px;
				}
			}


		}

		.ms-webpart-chrome {
			//background-color: white;
			border: 1px solid white;
    		border-radius: 4px;
			padding: 5px;
		}

		INPUT {
			color: white;
		}

		.ms-helperText, input.ms-helperText {
			color: white;
		
		}		
	}
	
}
