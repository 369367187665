HEADER
{
	background-color: $header-color-bg;
	padding-top: $padding-medium;
	padding-bottom: $padding-medium;
	margin-bottom: $padding-medium;

	background-repeat: no-repeat;
	background-position: bottom center;

	.pm-logo
	{
		display: block;
		
		IMG {
			max-width: 100%;
		}
	}
}
