// Mobile navigation solution 1
// -----------------------------------------------------------------------------
// TODO: extract code from structure when possible

#DeltaTopNavigation.ms-core-navigation
{
	&.mobile {
		display:block;
	}
	
	@include show-if-viewport-equals($breakpoint-ipad);
	
	@include respond-to(false, $breakpoint-ipad, false) {
		margin-left:-15px; // override bootstrap margins at mobile
		margin-right:-15px;
	}
	
	.ms-core-listMenu-horizontalBox // apply styling to the top (horizontal) navigation
	{
		display:block;
	
		UL,LI {
			@include change-display-if-viewport-equals($breakpoint-ipad, block, inline-block);
		}

		UL.dynamic {
			display:none; // hide secondary navigation by default, otherwise they exposes themselves on unhover
			@include respond-to($breakpoint-ipad, false, false) {
				display: block; // allow drop down to display on hover in desktop mode
			}
		}

		LI.dynamic {
			@include change-display-if-viewport-equals($breakpoint-ipad, block, list-item); // list item is sharepoint default
		}
	
		UL.ms-core-listMenu-root
		{
			LI.static {
				@extend %top-nav-menu-item-li-mobile;
			}
            
            LI.static { // top level
                @include respond-to(false, $breakpoint-ipad, false) {
                    line-height: #{map-get(nth($map-nav-mobile, 1), height)};
                    height: #{map-get(nth($map-nav-mobile, 1), height)};
                }
			}
			
			LI.dynamic { // list items in dropdown
                @include respond-to(false, $breakpoint-ipad, false) {
                    line-height: #{map-get(nth($map-nav-mobile, 2), height)};
                    height: #{map-get(nth($map-nav-mobile, 2), height)};
                }
			}

			@extend %indent-secondary-nav; // indent secondary navigation in mobile
			
			@include change-display-if-viewport-equals($breakpoint-ipad, block, inline-block);
			
			LI.static { // The entire first level navigation menu list
				height:auto; // Override: makes sure dropdowns aren't drawn on top of other navigation items
				
				> A, > .menu-item {
					@include change-display-if-viewport-equals($breakpoint-ipad, block, inline-block);

					@include respond-to(false, $breakpoint-ipad, false) {
						//padding-left: 15px;
						//padding-right: 0px;
						//@include border-box();
					}
				}
			
				&.dynamic-children { // Secondary navigation/dropdown
					&.hover:hover, // hover effects
					&.hover,
					&.hover-off {
						> UL.dynamic {
							@include show-if-viewport-equals($breakpoint-ipad);

							@include respond-to(false, $breakpoint-ipad, false)
							{
								position:static; // make dropdown positioning static in mobile
							}

							LI.dynamic-children
							{
								&:hover {
									> UL.dynamic {
										@include show-if-viewport-equals($breakpoint-ipad);
										
										@include respond-to(false, $breakpoint-ipad, false)
										{
											position:static; // make dropdown positioning static in mobile
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}