// Webpart editor UI

#MSOTlPn_MainTD
{
	position:relative;
	z-index:1400;
    
	.ms-TPBorder {
		width: 100%; // Prevents grey background in Webpart Editor from shrinking
	}
}