// Webparts
// -------------------------------------------

$webpart-color:					$color-accent-1;
$webpart-color-bg:				transparent;
$webpart-link-color:			$color-accent-2;
$webpart-link-visited-color:	$color-accent-2;
$webpart-link-hover-color:		$color-terciary;
$webpart-border-color:		    transparent; 
$webpart-header-color:			$color-accent-12; // webpart chrome title color
$webpart-header-color-bg:		transparent;

$border-color:                  $color-accent-5;

$header-color-bg:               $color-primary;
$pm-edit-mode-header-color-bg:	inherit;
$pm-edit-mode-header-color:		inherit;

$webpart-image-color-border:	$color-primary;

// Content Query Web Part
// -----------------------------------------------------------------------------

$cq-link-color: 		$color-primary;
$cq-link-color-hover:	$color-primary;
$cq-date-color:			$color-primary;
$cq-see-more-color:		$color-primary;
$cq-byline-color:		$color-primary;

$cq-title-with-background-color:		$color-primary;
$cq-title-with-background-color-bg:		transparent;

// Calendar Web Part
// -----------------------------------------------------------------------------

$webpart-calendar-item-color:			inherit;
$webpart-calendar-item-color-bg:		$color-primary;
$webpart-calendar-border-color:			$color-primary;
$webpart-calendar-today-color:			$color-primary;


// Table of Contents
// -----------------------------------------------------------------------------

$webpart-toc-color:				$color-primary;

$webpart-date-color-bg:			transparent;