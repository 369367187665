// Pill Navigation button
// -----------------------------------------------------------------------------

BUTTON.navbar-toggle,
{
	position: relative;
    float: right;
    top: 31px;
	
	background: url('../images/btn-toggle-navbar.png') no-repeat;
}