// A mobile nav styling that adds borders between nav items
// Instructions:
// @extend under .ms-core-navigation 
// -----------------------------------------------------------------------------
%navbar-chunky {

	&.mobile 
	{
		.mobileNavExpand 
		{
			//margin-top: $mobile-navigation-expand-padding;	// reposition cheveron
			// TODO: may need to calculate this in collapse/expand?
		}
	}
	.ms-core-listMenu-horizontalBox
	{
		//margin-top: 0; // why do we need this?
		UL.ms-core-listMenu-root 
		{
			LI.static 
			{
				A {
					@include change-display-if-viewport-equals($breakpoint-ipad, block, inherit); // force menu items to 100% width in mobile or borders won't be full width

					SPAN.menu-item-text 
					{
						@include change-display-if-viewport-equals($breakpoint-ipad, block, inherit); // force menu items to 100% width in mobile

						@include respond-to(false, $breakpoint-ipad, false) {
							border-top: 1px solid $mobile-navigation-border-color; // mobile menu item borders
						}
					}
				}
			}
			> LI.static { // top menu item only
				> A {
					SPAN.menu-item-text {
						@include respond-to(false, $breakpoint-ipad, false) { // removing border from top menu item in mobile
							border:none;
						}
					}
				}	
			}
		}		
	}
}