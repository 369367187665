

.styleLessList {
    UL {
        display:block;
        list-style:none;
        LI {
            display:inline-block;
            padding-top:15px;
            padding-bottom:15px;
        }
    }
    @extend .generalLink;
}

/**
* Apply to UL
**/
.styledList {
    list-style:disc;
    margin:15px;
    margin-left:15px;
}

.list-vertical {
	UL {
		LI {
			display:block;
		}
	}
}

.nav-horizontal {
    LI {
        float:left;
    }
}

.generalLink {
    a, a:link, a:hover, a:visited, a:active {
        text-decoration: none;
        &:hover {

        }
    }
}
.plainLink {
    A {
        font-size:$font-size-normal;
        font-weight:bold;
        text-decoration:none;
    }
}