// mobileExpanded - a nav item was expanded and we can see items below

.mobile {
	.mobileExpanded // TODO: This file needs to be simplified
	{
		> UL.dynamic {
			display: block !important; // when nav item is collapsed, make it visisble 
						// set to important to override the .hover class
						// TODO: !important has to go because it is overriding our own CSS
		}
		UL.dynamic
		{
			position: relative;
			top: 0 !important;
			left: 0 !important;

			width: auto;
		}
	}

	.mobileNavExpand {

		background-position: 0px 2px;
		&.active {
			background-position: 0 -37px;
		}

		display: block;
		top: 5px;

		position: absolute;
		right: 15px;
		left: auto;

		float: none;

		width: 37px;
		height: 37px;

		cursor: pointer;

		background-image: url('../images/mobile-expand.png');
		background-repeat: no-repeat;
	}
}