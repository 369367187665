//the primary wrapper for the layouts table for the wiki layout
.ms-wikicontent {
    @include respond-to(false, $breakpoint-ipad, false) {
        padding: 0px !important; //must override inline style added by SP
    }
}


table#layoutsTable {
    
    //less code required to make wiki layout desktop first, so for mobile apply new stlying
    @include respond-to(false, $breakpoint-ipad, false) {
        display: block;

        > tbody {
            display: block;

            > tr {
                display: block;

                > td {
                    display: block;

                    width: auto !important;
                    padding: 0;
                }
            }
        }

        //we do not want the zones to float on mobile
        .ms-rte-layoutszone-outer {
            float: none;
        }
    }
}

