%pagination {
	LI {
		display: inline-block;
		vertical-align: bottom;
		@include border-box();
		width: 32px;
		height: 32px;
		border: 1px solid transparent; // placeholder to avoid shifting

		A {
			display: block;
			cursor: pointer;
			padding: 7px;
			font-family: "Roboto", sans-serif;
			color: $color-primary;
			line-height: 1;
			text-align: center;
			border: none;
			background-color: transparent;

			&:hover,
			&:visited {
				color: $color-primary !important; // override searchv15
				text-decoration: none;
			}
		}

		&:hover {
			border: 1px solid $color-accent-11;
		}

		&#PagingSelf { // active page
			background-color: $color-primary;
			border: 1px solid $color-primary;
			A,
			A:hover,
			A:visited {
				color: white !important; // override searchv15
			}
		}
		&#PagingLink { // non-active page numbers
			background-color: transparent;
		}
		&#PagingImageLink { // prev/next
			A {
				SPAN {
					&:before {
                		font-family: 'icomoon';
                		font-size: 16px;
                		line-height: 1;
                		color: $color-primary;
					}
					IMG {
						display: none; // hide OOTB sprite icon
					}
				}
				&#PageLinkNext {
					SPAN {
						&:before {
							content: "\e93f"; // icomoon arrow right
						}
					}

				}
				&#PageLinkPrev {
					SPAN {
						&:before {
							content: "\e93e"; // icomoon arrow left
						}
					}
				}


			}
		}
	}

	&.pagerMoveToFirst:before {
        content: "\e929"; //double arrow
		font-family: 'icomoon';
		font-size: 16px;
		color: $color-primary;
		position: relative;
		top: 6px;
    	left: 5px;
	}

	&.pagerMoveToLast:before {
		content: "\ea26"; //double arrow
        font-family: 'icomoon';
		font-size: 16px;
		color: $color-primary;
		position: relative;
		top: 6px;
    	left: 5px;
	}
}
