// Searchbox webpart - NOT the top navigation searchbox
// -----------------------------------------------

.ms-srch-siteSearchResults
{
	#SearchBox 
	{
		.ms-srch-sbLarge-fullWidth, .ms-srch-sbLarge {

			> INPUT {
				width: 100%; // prevent searchbox input from breaking the container
				@include styleguide('searchbox text', false);
			}
		}

		border: 2px solid $webpart-border-color;

	}
}