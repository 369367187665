.pm-news-band-colored {
    //padding-left: 0;
    //padding-right: 0; //overriding bootstrap to go 100% full width;
    padding-top: 15px; //need to breathe a bit more - may not be needed in final styling
    background-color: $color-accent-3;

    H1 {
        font-size: 36px !important;
        color: $color-accent-5 !important;
        text-transform: uppercase;
        font-weight: bold;
    }
    H2 {
        font-size: 24px !important;
        color: black !important;
    }

    UL {
        
        margin-left: 0 !important; //todo override our framework - take out important
        margin-top: 10px !important; //take out important
    }

    LI {
        list-style: none;
        line-height: 1.65em;
        border-bottom: 1px solid $color-accent-17;
        color: $color-primary;
        padding-bottom: 5px; //adding both margin and padding to create separation of the border-bottom
        margin-bottom: 5px;

        A {
            color: $color-primary;
        }
    }
    
}