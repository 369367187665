$pagination-arrow-width:		23px;
$pagination-arrow-height:		23px;

$pagination-container-width:	23px;
$pagination-container-height:	23px;

$pagination-arrow-right-url:			url(../images/arrow-right.png);
$pagination-arrow-right-deactivate-url:	url(../images/arrow-right-deactivate.png);
$pagination-arrow-right-hover-url:		url(../images/arrow-right-hover.png);

$pagination-arrow-left-url:				url(../images/arrow-left.png);
$pagination-arrow-left-deactivate-url:	url(../images/arrow-left-deactivate.png);
$pagination-arrow-left-hover-url:		url(../images/arrow-left-hover.png);

.ms-promlink-header {
	list-style:none; // remove bullet from pagination
	padding-bottom:0px;
}

// IE mod Sharepoint reset - Sharepoint attaches the class to BODY if IE

.ms-core-needIEFilter 
{
	.ms-promlink-button-enabled {
		filter: progid:DXImageTransform.Microsoft.gradient(enabled = false); // remove gradient on icons we don't need
	}
	.ms-promlink-button-disabled {
		filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
	}
}

.ms-promlink-button
{
	padding:0px;
	border:0px;
	width: $pagination-container-width;
	height: $pagination-container-height;

	&.ms-promlink-button-enabled 
	{
		background-color:transparent;
	}
	&.ms-promlink-button-disabled
	{
		background-color:transparent;
	}
	.ms-promlink-button-image 
	{
		width: $pagination-container-width;
		height: $pagination-container-height;

		
		.ms-srch-pagingNext,
		.ms-promlink-button-right-disabled,
		.ms-promlink-button-right
		{
			background: url(../images/arrow-right.png) no-repeat;
			display: block;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			// may need algorithm to calculate top/left margins based on container and image dimensions
			width: $pagination-arrow-width; /* Width of new image */
			height: $pagination-arrow-height; /* Height of new image */
			padding-left: $pagination-arrow-width;

			left:0px;
			top:0px;
			position:static;
		}
		.ms-promlink-button-right-disabled,
		{
			cursor: default;
			background: url(../images/arrow-right-deactivate.png) no-repeat;
		}
		.ms-srch-pagingNext:hover,
		.ms-promlink-button-right:hover {
			background: url(../images/arrow-right-hover.png) no-repeat;
		}
		.ms-srch-pagingPrev,
		.ms-promlink-button-left,
		.ms-promlink-button-left-disabled {
			background: url(../images/arrow-left.png);
			display: block;
			-moz-box-sizing: border-box;
			box-sizing: border-box;

			width: $pagination-arrow-width; /* Width of new image */
			height: $pagination-arrow-height; /* Height of new image */
			padding-left: $pagination-arrow-width;

			left:0px;
			top:0px;
			position:static;
		}
		.ms-promlink-button-left-disabled {
			cursor: default;
			background: url(../images/arrow-left-deactivate.png) no-repeat;
		}
		.ms-srch-pagingPrev:hover,
		.ms-promlink-button-left:hover {
			background: url(../images/arrow-left-hover.png) no-repeat;
		}
	}

	.ms-promlink-button-image:hover
	{
	}
}

.ms-promlink-button-disabled {
	.ms-promlink-button-image {
		width: $pagination-arrow-width;
		height: $pagination-arrow-height;
	}
}