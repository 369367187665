// styles the link instead of A 

%pm-link-colors {
    color:$link-color;
    &:link,&:active {
        color:$link-color;
    }
    &:visited {
        color:$link-visited-color;
    }
    &:hover {
        color:$link-hover-color;
    }
}