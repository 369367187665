%headerBar-base {
	height: 25px;
	line-height: 24px;
	padding-left: 5px;
    margin: auto;
}

%headerBar
{
    @extend %headerBar-base;
	
	background: $webpart-header-color-bg;
	@include styleguide('webpart title', false, $webpart-header-color);
    > A {
		@include styleguide('webpart title', false, $webpart-header-color);
	}
}
