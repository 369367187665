// Undo some of Sharepoint OOTB styling so we can style it.

// Structure RESET
//
// - only specify STRUCTURE here
// -----------------------------------------------------------------------------

#SearchBox, // Searchbox webpart
 // Searchbox in the header, embedded in the master page
{	
	display:inline-block;
	width:100%;

	.ms-srch-sb 
	{
		width:100%;

		>INPUT {
			margin:0px; // sharepoint reset
		}
	}

    .ms-srch-sbLarge-fullWidth, .ms-srch-sbLarge
	{
        width:100%; // Sharepoint override: prevent searchbox input from breaking the container
		
		> INPUT {
			width:100%;
		}
    }

	.ms-srch-sb-border {
		border:0px; // sharepoint reset
	}
	.ms-srch-sb-borderFocused {
		border:0px; // sharepoint reset
	}
	.ms-srch-sb-searchLink,
	.ms-srch-sb-navLink {
		display:none;
		border:0px; // sharepoint reset: remove invisible border
	}
}

