#DeltaTopNavigation.ms-core-navigation
{
	.ms-core-listMenu-horizontalBox // apply styling to the top (horizontal) navigation
	{
		.dynamic-children.additional-background // Sharepoint reset: suppress secondary navigation dropdown image
		{ 
			padding:0px;
			background:none;
		}
		UL.ms-core-listMenu-root {

			UL.dynamic { // dropdown container
				@extend %ul-dynamic;
			}
			
			// list items in dropdown
			LI.dynamic { 
				line-height: #{map-get(nth($map-nav, 2), height)};
				height: #{map-get(nth($map-nav, 2), height)};
			}

			// The entire first level navigation menu list
			LI.static {
				line-height: #{map-get(nth($map-nav, 1), height)};
				height: #{map-get(nth($map-nav, 1), height)};

				@extend %top-nav-menu-item-li;

				> A, > SPAN { // first menu item, treated like an LI instead of an A
					&.ms-core-listMenu-selected,
					&.ms-core-listMenu-item,
					&.menu-item {
						@extend %top-nav-menu-item-a;
					}
				}
				> UL.dynamic {
					display: none;
					@include respond-to($breakpoint-ipad, false, false) { // restrict to desktop view
						//top:  #{map-get(nth($map-nav, 2), height)} !important; // dropdown vertical position TODO: Recalculate based on the height of navigation items
						@include position-absolute( #{map-get(nth($map-nav, 1), height)} !important, false, false, 0px !important); // overriding js
					}

					//for any dynamic decendants, not just children
					LI {
						> UL {
							display:none;
							
							@include respond-to($breakpoint-ipad, false, false) {
								@include position-absolute(0px !important, false, false, $navigation-item-width !important); // overriding js
							}
						}
						&.hover {
							//on hover, show only the child ul
							> UL {
								@include respond-to($breakpoint-ipad, false, false) { // restrict to desktop view
									display:block;
								}
							}
						}
					}
				}
				
				&.hover:hover, // hover effects
				&.hover,
				&.hover-off {
					> UL.dynamic {
						@include respond-to($breakpoint-ipad, false, false) { // restrict to desktop view
							display: block;
						}
					}
				}

			}
		}
	}
}