.pm-system-master
{
	#s4-bodyContainer
	{
		.main-content
		{
			#sideNavBox
			{
				float: none; 
				margin: 0;
				width: 100%;

				padding-top:$padding-large;
				padding-bottom:$padding-large;

				@include respond-to(768px, false, true) {
					padding:0;
				}

				.ms-core-navigation 
				{
					.ms-core-listMenu-verticalBox // vetical navigation/quicklaunch
					{ 
						
						display: none; // hide for mobile
						
						@include respond-to(768px, false, true) {
							display: block; // display vertical navigation/quicklaunch 
						}

						UL {
							padding:0px;
							margin:0px;
						}
						> .ms-core-listMenu-root {
							> li {
								> .ms-core-listMenu-item {
									
									padding-left:$padding-large;
								}
								> .ms-core-listMenuEdit {
									
								}

							}
						}
						
					}
					.ms-splinkbutton-text {
						padding-left:$padding-large;
					}
				}
			}
		}
	}
}
