// IE10 + Windows 8 Phone viewport fix
// ------------------------------------------

@-ms-viewport{
    width: device-width;
}

// Local Sharepoint - enable to test locally
// --------------------------------------------

//@import '../../resources/css/sharepoint/corev15';    // activate these files when developing locally
//@import '../../resources/css/sharepoint/searchv15';
//@import '../../resources/css/sharepoint/authors';

// Third Party CSS
// ------------------------------------
$media-queries:true;
$peg-width:false; // set $peg-width to true to prevent layout to shrink beyond a certain point
$mobile-nav-plugin:"mobile"; // mobile plugin name - determines what styling is used to render mobile nav/searchbox

@import '../../resources/css/mixins/respond-to';
@import '../../resources/css/bootstrap/bootstrap.grid.only.ie8';
//@import '../../resources/css/compass/compass'; // Compass
@import '../../client/css/third-party';
@import '../../client/css/layouts';

// Resets
// -------------------------------------

@import '../../resources/css/resets/reset';
@import '../../resources/css/core/sharepoint';
@import '../../resources/css/core/classes';

// Compass
// ------------------------------------------------

//@import '../../resources/css/compass/compass/css3/border-radius';

// Project-specific CSS
// -------------------------------------
@import '../../resources/css/variables/variables';
@import '../../client/css/variables';
@import '../../client/css/maps';
@import 'elements/mixins';
@import '../../client/css/mixins';

// Others
// -----------------------------------------------------------------------------
@import 'elements/classes';
@import '../../client/css/classes';
@import '../../client/css/font-face';

// Layout
// -------------------------------------

@import '../../resources/css/layouts/wireframe';
@import '../../resources/css/layouts/edit-mode';
//@import '../../resources/css/layouts/search';
//@import '../../resources/css/layouts/system';
@import '../../resources/css/layouts/system-v2';

// Author Mode
// -------------------------------------

@import '../../resources/css/sharepoint/authors';

// Components
// -------------------------------------

@import '../../resources/css/components/dialog';
@import '../../resources/css/components/pagination';
//@import '../../resources/css/components/quick-launch-public-flyout'; // 'public' master page vertical navigation with flyout
//@import '../../resources/css/components/quick-launch-public'; // 'public' master page vertical navigation
@import '../../resources/css/components/quick-launch'; // system master page vertical navigation
@import '../../resources/css/components/ribbon';
@import '../../resources/css/components/webpart-editor';
@import '../../resources/css/components/edit-mode-panel';
@import '../../resources/css/components/top-navigation';
@import '../../resources/css/components/wiki-layout';
@import '../../resources/css/components/site-settings';

@import 'elements/components/breadcrumb';
@import 'elements/components/footer';
@import 'elements/components/header';
@import 'elements/components/main-content';
@import 'elements/components/btn-toggle'; // pill button base class
@import 'elements/components/btn-toggle-navbar'; // Mobile pill navigation button
@import 'elements/components/btn-toggle-searchbar'; // Mobile pill navigation button
@import 'elements/components/article-content';
@import 'elements/components/quick-launch';
@import 'elements/components/logo';

@import '../../client/css/components';

// Webpart
// -------------------------------------

@import '../../resources/css/webparts/default-site-wide';
@import '../../resources/css/webparts/content-query';
@import '../../resources/css/webparts/image-viewer';
@import '../../resources/css/webparts/media-web-part';
@import '../../resources/css/webparts/list-view-table';
@import '../../resources/css/webparts/inline-search';
@import '../../resources/css/webparts/tree-view';

@import '../../resources/css/webparts/searchbox/body'; // searchbox webpart in content body
@import '../../resources/css/webparts/searchbox/header'; // Mobile pill navigation button
@import '../../resources/css/webparts/searchbox/mobile'; // header searchbox in mobile
@import '../../resources/css/webparts/searchbox/reset'; // searchbox general Sharepoint reset
@import '../../resources/css/webparts/searchbox/search'; // searchbox on search page
@import '../../resources/css/webparts/searchbox/ui'; // searchbox icon and dropdown

@import 'elements/components/slideshow'; // TODO: This should be a webpart

@import 'elements/webparts/content-editor';
@import 'elements/webparts/content-query';
@import 'elements/webparts/content-search';
@import 'elements/webparts/default';
@import 'elements/webparts/document-list';
@import 'elements/webparts/holiday-calendar';
@import 'elements/webparts/master-page-gallery';
@import 'elements/webparts/ms-summarystandardbody';
@import 'elements/webparts/search-refiner';
@import 'elements/webparts/summary-links';
@import 'elements/webparts/survey';
@import 'elements/webparts/table-of-contents';
@import 'elements/webparts/menu-toolbar';

@import '../../client/css/webparts';

// Plugins
// -------------------------------------

@import 'elements/plugins';
@import '../../client/css/plugins';
@import '../../resources/plugins/scroll-list/scroll-list';