// base class to style mobile toggle buttons

%btn-toggle
{
	@extend %reset-sharepoint-button; // removes OOTB sharepoint styling on buttons
	
	@include hide-if-viewport-equals($breakpoint-ipad);

	height: 40px;
	width: 40px;
	
	@include vertical-align-simple(40px);
}