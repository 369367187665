.ms-ref-refiner { // container for each refiner
	padding: 0;
	margin-bottom: $padding-large;

	.ms-ref-refinername { // refiner category (ie "Result Type")
		width: 100%;
		height: 18px;
		line-height: 21px;
		padding-bottom: 10px;
		margin-bottom: $padding-small;
		//border-bottom: solid 2px $color-primary;
		background-color: $color-secondary;
		@include styleguide('refiner category title', false, $search-refiner-title-color);
	}

	.ms-ref-selSec, .ms-ref-allSec {
		#Value {
			@include border-box();
			padding: $padding-medium 0;
			//border-bottom: solid 1px $color-accent-8;
			width: 100%;
			> A {
				@include styleguide('refiner item', false, $search-refiner-title-color);
				&:hover {
					@include styleguide('refiner item', false, $search-refiner-link-color-hover);
				}
			}
		}
	}

	.ms-ref-unsel-toggle {
		@extend %pm-link-colors;
		@include styleguide('refiner show hide', false, $color-primary);
	}

	.ms-ref-name {
    	color: $color-accent-1;
	}

	.ms-ref-refiner #Value > a:hover, .ms-ref-unsel-toggle:hover {
		color: $color-accent-1;
	}
		

}

#Value {
	A#FilterLink {
		@include styleguide('refiner item', false, $search-refiner-title-color);
		font-family: $font-stack;
		//text-transform: capitalize;
		&:hover {
			@include styleguide('refiner item', false, $search-refiner-title-color);
		}
		#RefinementName {
			&:before {
				content: "";
				display: inline-block;
				height: 12px;
				width: 12px;
				border: 1px solid $color-primary;
				line-height: 18px;
				position: relative;
				top: 3px;
				margin-right: 3px;
				padding-right:8px;
				padding-bottom: 6px;
			}
		}
		&.ms-core-listMenu-selected {
			#RefinementName {
				&:before {
					content: "\f00c";
					font-family: 'fontawesome';
					font-size: 14px;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					color: $color-primary;
				}
			}
		}
	}
}
