// Mixin used to render Sharepoint top navigation expand arrow
// This mixin draws a carrot
//
// @param $showInMobile - usually we don't want to see this image in mobile
// --------------------------------------------------------------------

@mixin expand-arrow($color, $width:5px, $margin:5px, $showInMobile:false)
{
	UL.dynamic 
	{
		LI.dynamic.dynamic-children {
			> A {
				> SPAN.additional-background:after
				{
					@if $showInMobile == false
					{
						@include respond-to($breakpoint-ipad, false, false)
						{
							content: " ";
							position: absolute;
							right: 10px;
							margin-top: 10px; // have to use margin because absolute top will put all arrows together in 1 li
							@include carrot("right", $color, $width);
						}
					}
				}
			}
		}
	}
}