// Resets sharepoint CSS on:
// input[type=button], input[type=reset], input[type=submit], button
//
// Use this class locally - global declaration will bleed and break styling.

%reset-sharepoint-button
{
	min-width: inherit;
	padding: 0px;
	border: none;
	background-color: inherit;
	margin: 0px;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	vertical-align:inherit;
	cursor: pointer; // not an override but all buttons need it
}