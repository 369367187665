/*
* Sharepoint Web Parts Draggable UI Mod CSS
*/
#MSO_tblPageBody {
	table-layout: fixed;
	vertical-align: top;

	> tbody {
		> tr {
			> td {
				width: .5% !important;
				display: inline-block;
				vertical-align: top;

				&:first-child {
					width: 99.5% !important;
					vertical-align: top;
				}
			}
		}
	}
}

/*webpart tool pane column*/
#MSOTlPn_MainTD {
	height: 10px !important;
	z-index: 999;
}
/*tool pane cell wrapper*/
#MSOTlPn_MainTD_Div {
	position: relative;
	width: 0px !important;
	height: 0px !important;
	z-index: 998;
}

/*toolpane table*/
#MSOTlPn_Tbl > tbody, #MSOTlPn_Tbl > tbody > tr, #MSOTlPn_Tbl > tbody > tr > td {
	display: block;
}
#MSOTlPn_Tbl {
	display: table !important;
	position: relative;
	top: 0px;
	left: 0px;
	margin: 0px;
	z-index: 997;

	/*make sure that inside tables act as tables*/
	table {
		display: table;
	    width: 100%;
	}
}
#MSOTlPn_ToolPaneCaption:hover {
	cursor: move;
}
#MSOTlPn_MainTD .ms-TPSectionTD, #MSOTlPn_MainTD .ms-TPBody .ms-propGridTable {
    padding: 5px 5px 0;
}
#MSOTlPn_Parts {
	background: #fff none;
}
/*end webpart tool pane*/