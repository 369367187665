%webpart-container {

	// TODO: move into a webpart-container class file
	
	// basic container class that wraps things
	// WARNING: This file should control the skin only, not layout
    border: 1px solid $webpart-border-color;
    
	background-color: $webpart-color-bg;
}

