.ms-welcomepageheader {
	.ms-WPBody {
		.ms-rtestate-field {
			IMG {
				margin: 0px; //images in the header webparts should not have margin applied
			}
		}
	}
}

.ms-searchCenter {
	.ms-searchCenter-main {
		width: 100%;
		margin-top: 20px;
		min-height: 200px;

		@include respond-to($breakpoint-ipad, false, false) {
			margin-top: 100px;
			min-height: 300px;
  			width: 500px;
		}
	}

	#SearchBox {
		display: block;
		width: auto;
		padding: 20px;
		float: none;

		.pm-system-master & {
			padding-bottom: 20px; //make sure that on basic search using system mp, padding remains under search box
		}

		.ms-srch-sbLarge {
			padding: 5px;
		}
	}
}