

#s4-bodyContainer 
{
	.main-content {
		//overflow-x: hidden; //this is to remove the scrollbar with full width

		&.fixedHeader {
			padding-top: 60px;
		}
		
		H1[data-name="Page Field: Title"] {
			font-size: 30px;
			font-family: $font-stack;
			color: $color-accent-5;
			font-weight: bold;
		}

		 .container {
			//When specified as fluid
			//width: 100% !important; //need this to extend out the bands inside the containers
			padding: 0; //changing out padding to see if this removes the scrollbar
			.pm-fluid & {
				@include respond-to($breakpoint-ipad, false, false) {
			//		width: 100%;
				}
			}
		}

		.pm-column-article {
			//in full screen mode, article should be 100%
			.ms-fullscreenmode & {
				width: 100%;
			}
		}

		.pm-column-sidebar {
			//in full screen mode, hide left navigation
			.ms-fullscreenmode & {
				display: none;
			}
		}
	}

	//used for wide tables on system pages such as list views. Also used with #DeltaPlaceHolderMain to provide scrollbars
	.pm-system-master & { 
		position: relative;
		overflow: hidden;


		.main-content {
			.pm-column-article {
				overflow: inherit; //will make the article overflow to visible. Must also keep position: static so that list menus appear outside of scroll area
				//margin-bottom: $padding-xlarge;

				//used for wide tables on system pages such as list views
				> #DeltaPlaceHolderMain {
					display: block;
					overflow: auto;
				}
			}

			.ms-listviewtable {
				.ms-positionRelative {
					position: static; //for list views, the action button, we need the column in question to not allow relative child containers, can cause additional scrollbars. Must be static position up through chain to bodyContainer
				}
			}
		}

		
	}
}