// Font
// -----------------------------------------------------------------------------

$font-stack:		'Catamaran', sans-serif;
$font-stack-alt:	 'Gudea', sans-serif;

$font-size-h1:		22px;
$font-size-h2:		20px;
$font-size-h3:		18px;
$font-size-h4:		16px;
$font-size-h5:		14px;
$font-size-h6:		12px;
$font-size-h7:		11px;

$font-size-normal: $font-size-h4;
$font-size-large: 36px;
$system-font-size-normal: $font-size-h6;