//
// WARNING: This file contains SKIN rules only - no CSS that defines structure
// should be written in this file. Structure CSS belongs in the layout file

#DeltaTopNavigation.ms-core-navigation {
	.ms-core-listMenu-horizontalBox
	{
		@include styleguide("top navigation",false);

		UL.ms-core-listMenu-root
		{
            @include top-nav-top-level(nth($map-nav-mobile, 1));
            @include top-nav-dropdown(nth($map-nav-mobile, 2));
            
            @include respond-to($breakpoint-ipad, false, false)
            {
                @include top-nav-top-level(nth($map-nav, 1));
                @include top-nav-dropdown(nth($map-nav, 2));
            }
            
			@include expand-arrow($navigation-expand-arrow-color, $navigation-expand-arrow-width);
		}
	}
}

