FOOTER {
	background-color: $footer-color-bg;
	padding-bottom: $padding-large;
	padding-top: $padding-large;

	background-repeat: no-repeat;
	background-position: top center;

	.pm-login, .pm-copyright
	{
		float: right;
		clear: both;
	}
	.pm-login {
		margin-bottom: 10px;
		@include styleguide('h5', false, $footer-color);
	}
	.pm-copyright {
		@include styleguide('copyright', false, $footer-color);
	}
}