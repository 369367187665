// Global colors and fonts
// ------------------------------------

$color-primary:		#426d8d; // header color
$color-secondary:	#f2f3ee; // webpart background color	
$color-terciary: 	#5281a4; // bottom footer color

$color-accent-1:	#444; // text color
$color-accent-2:	#436d8d; // top footer color
$color-accent-3:	#f2f3ee; // webpart alt background color
$color-accent-4:	#545553; // green icon background color - green link colors
$color-accent-5: 	#545553; // border-color
$color-accent-6:	inherit; // breadcrumb color
$color-accent-7:	inherit; // top-nav background color
$color-accent-8:	inherit; // accent color
$color-accent-9:	inherit; // references color
$color-accent-10:	#36507a; //facebook background color 
$color-accent-11:	#198bad; //twitter background color 
$color-accent-12:	#7291a7; //chrome title header text color 
$color-accent-13:	#788035; // green icon background color - green link colors 
$color-accent-14:	inherit; 
$color-accent-15:   #a8a9a5; //top nav hover/selected color
$color-accent-16:   #6e6f6a; //alt text color
$color-accent-17:   #ccc; //temp border-bottom color on home page news section



