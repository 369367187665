$alert-color:					black;
$alert-color-bg-announcement:	$color-terciary;
$alert-color-bg-warning:		$color-accent-13;
$alert-color-bg-critical:		$color-accent-14;

.pm-alert {
	display: none;
	padding: $padding-large;
	@include styleguide('p', false, $alert-color);
	@include border-box();

	&.visible {
		display: block;
		margin-bottom: 10px;
	}

	&.announcement {
		background-color: $alert-color-bg-announcement;
	}
	&.warning {
		background-color: $alert-color-bg-warning;
	}
	&.critical {
		background-color: $alert-color-bg-critical;
	}

	UL {
		LI {
			display: none;
			&.pm-active {
				display: block;
			}
		}
	}

	.pm-alert-controls {
		@include styleguide('h4', false, $alert-color);

		text-align: right;

		.pm-alert-counter {
			display: inline-block;
			vertical-align: top;
			position: relative;
		}
		.pm-alert-close {
			display: none;
			cursor: pointer;
			vertical-align: top;
			position: relative;
		}
		.pm-alert-next {
			display: inline-block;
			cursor: pointer;
			vertical-align: top;
			position: relative;
		}
	}
}