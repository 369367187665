// Searchbox
// -------------------------------------------

$searchbox-color-bg:			inherit;
$searchbox-label-color-bg:		inherit;
$searchbox-label-color:			inherit;
$searchbox-input-color-bg:		inherit;
//$searchbox-border-color:		grey; // not used
$searchbox-input-padding:		5px; // padding inside searchbox input
$searchbox-input-width:     	221px;
$mobile-searchbox-input-width:	100%