.pm-slider {
 //   padding-left: 0;
 //   padding-right: 0; //overriding bootstrap to go 100% full width;

    .pm-slider-img {
        width: 100%;
    }

    .ms-WPBody {
        padding: 0 !important; //important overriding a bunch of framework styling
    }
    .ms-webpartzone-cell {
        margin: 0;
    }
}