// vertical aligns a block element
// The parent needs to have position:relative
// 
// @param $element-height - height of the block element we're trying to center

@mixin vertical-align-simple($element-height)
{
	position:absolute;
	top:50%;
	margin-top:-$element-height/2;
}